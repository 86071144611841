























import { Component, Vue } from 'vue-property-decorator';
import NotificationsManager from '@/components/NotificationsManager.vue';
// import { readIsLoggedIn } from '@/store/main/getters';
// import { dispatchCheckLoggedIn } from '@/store/main/actions';
// import { EventBus } from '@/main';
// import { EventSourcePolyfill } from 'event-source-polyfill';
// import { apiUrl } from '@/env';

@Component({
  components: {
    NotificationsManager,
  },
})
export default class App extends Vue {
  get loggedIn() {
    return this.$store.getters['api/isLoggedIn'];
  }

  public async created() {
    // await dispatchCheckLoggedIn(this.$store);
  }
  public mounted() {
    // this.connectSSE();
  }

  // private async connectSSE() {
  //   const localVarPath = '/api/v1/sse/status';
  //   const url = apiUrl + localVarPath;
  //   const source = new EventSourcePolyfill(url, {
  //     headers: {
  //       Authorization:
  //         'Bearer ' + this.$store.state.main.configuration.accessToken,
  //     },
  //     format: 'json',
  //     withCredentials: true,
  //     polyfill: true,
  //   });
  //   source.addEventListener('update', (event) => {
  //     const data = JSON.parse(event.data);
  //     // console.log(data);
  //     EventBus.$emit(data.table, data);
  //   });
  //   source.onerror = () => {
  //     source.close();
  //   };
  // }
}
