import Vue from 'vue';
import Router from 'vue-router';

import RouterComponent from './components/RouterComponent.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ './views/main/Start.vue'),
      children: [
        {
          path: 'login',
          name: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
        },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ './views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ './views/main/Dashboard.vue'),
            },
        //     {
        //       path: 'profile',
        //       component: RouterComponent,
        //       redirect: 'profile/view',
        //       children: [
        //         {
        //           path: 'view',
        //           component: () => import(
        //             /* webpackChunkName: "main-profile" */ './views/main/profile/UserProfile.vue'),
        //         },
        //         {
        //           path: 'edit',
        //           component: () => import(
        //             /* webpackChunkName: "main-profile-edit" */ './views/main/profile/UserProfileEdit.vue'),
        //         },
        //         {
        //           path: 'password',
        //           component: () => import(
        //             /* webpackChunkName: "main-profile-password" */ './views/main/profile/UserProfileEditPassword.vue'),
        //         },
        //       ],
        //     },
            {
              path: 'maintenance',
              component: () => import(/* webpackChunkName: "main-maintenance" */ './views/main/maintenance/Maintenance.vue'),
              redirect: 'maintenance/map',
              children: [
        //         {
        //           path: 'map',
        //           name: 'main-maintenance-map',
        //           component: () => import(
        //             /* webpackChunkName: "main-maintenance-map" */ './views/main/maintenance/StatusMapView.vue'),
        //         },
                {
                  path: 'organisations',
                  redirect: 'organisations/all',
                },
                {
                  path: 'organisations/all',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-organisation-list" */ './views/main/maintenance/OrganisationList.vue'),
                },
                {
                  path: 'organisations/:organizationId',
                  name: 'main-maintenance-organisation-view',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-organisation-view" */ './views/main/maintenance/OrganisationView.vue'),
                  props: route => { return { organizationId: parseInt(route.params.organizationId) } }
                },
                {
                  path: 'organisations/:organizationId/locations/:locationId',
                  name: 'main-maintenance-location-view',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-location-view" */
                    './views/main/maintenance/LocationView.vue'),
                  props: route => {
                    return {
                      organizationId: parseInt(route.params.organizationId),
                      locationId: parseInt(route.params.locationId)
                    }
                  }
                },
                {
                  path: 'organisations/:organizationId/locations/:locationId/devices/:deviceId',
                  name: 'main-maintenance-device-view',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-device-view" */
                    './views/main/maintenance/DeviceView.vue'),
                  props: route => {
                    return {
                      organizationId: parseInt(route.params.organizationId),
                      locationId: parseInt(route.params.locationId),
                      deviceId: parseInt(route.params.deviceId)
                    }
                  }
                },
                {
                  path: 'organisations/:organizationId/locations/:locationId/devices/:deviceId/edit',
                  name: 'main-maintenance-device-edit',
                  component: () => import(
                      /* webpackChunkName: "main-maintenance-device-edit" */
                      './views/main/maintenance/DeviceEdit.vue'),
                  props: route => {
                    return {
                      organizationId: parseInt(route.params.organizationId),
                      locationId: parseInt(route.params.locationId),
                      deviceId: parseInt(route.params.deviceId)
                    }
                  }
                },
                {
                  name: 'main-maintenance-fmon-daemon-registration-requests-list',
                  path: 'fmon-daemon-registration-requests/all',
                  component: () => import('./views/main/maintenance/RegistrationRequestList.vue')
                },
                {
                  name: 'main-maintenance-fmon-daemon-registration-request-view',
                  path: 'fmon-daemon-registration-requests/:requestId',
                  component: () => import('./views/main/maintenance/RegistrationRequestView.vue'),
                  props: route => {
                    return {
                      requestId: parseInt(route.params.requestId)
                    }
                  }
                },
                {
                  path: 'alerts',
                  redirect: 'alerts/all',
                },
                {
                  path: 'alerts/all',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-alarm-list" */ './views/main/maintenance/AlarmList.vue'),
                },
                {
                  path: 'alerts/:alarmId',
                  name: 'main-maintenance-alarm-view',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-alarm-view" */ './views/main/maintenance/AlarmView.vue'),
                  props: true
                },
                {
                  path: 'events',
                  redirect: 'events/all',
                },
                {
                  path: 'events/all',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-event-list" */ './views/main/maintenance/EventList.vue'),
                },
                {
                  path: 'organisations/:organizationId/locations/:locationId/devices/:deviceId/events/:eventId',
                  name: 'main-maintenance-event-view',
                  component: () => import(
                    /* webpackChunkName: "main-maintenance-event-list" */ './views/main/maintenance/EventView.vue'),
                  props: route => {
                    return {
                      organizationId: parseInt(route.params.organizationId),
                      locationId: parseInt(route.params.locationId),
                      deviceId: parseInt(route.params.deviceId),
                      eventId: parseInt(route.params.eventId)
                    }
                  }
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ './views/main/admin/Admin.vue'),
              redirect: 'admin/organisations/all',
              children: [
                {
                  path: 'organisations',
                  redirect: 'organisations/all',
                },
                {
                  path: 'organisations/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organisations" */ './views/main/admin/OrganisationsList.vue'),
                },
                {
                  path: 'organisations/edit/:organizationId',
                  name: 'main-admin-organisations-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-organisations-edit" */ './views/main/admin/OrganisationsEdit.vue'),
                  props: route => { return { organizationId: parseInt(route.params.organizationId) } }
                },
                {
                  path: 'organisations/edit/:organizationId/locations',
                  redirect: 'organisations/edit/:organizationId',
                },
                {
                  path: 'organisations/edit/:organizationId/locations/all',
                  redirect: 'organisations/edit/:organizationId',
                },
                {
                  path: 'organisations/edit/:organizationId/locations/create',
                  name: 'main-admin-locations-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-locations-create" */ './views/main/admin/LocationsCreate.vue'),
                  props: route => {
                    return {
                      organizationId: parseInt(route.params.organizationId)
                    }
                  }
                },
                {
                  path: 'organisations/edit/:organizationId/locations/edit/:locationId',
                  name: 'main-admin-locations-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-locations-edit" */ './views/main/admin/LocationsEdit.vue'),
                  props: route => {
                    return {
                      organizationId: parseInt(route.params.organizationId),
                      locationId: parseInt(route.params.locationId)
                    }
                  }
                },
        //         {
        //           path: 'organisations/edit/:organisationId/locations/edit/:locationId/fmonds/edit/:fmondId',
        //           name: 'main-admin-fmonds-edit',
        //           component: () => import(
        //             /* webpackChunkName: "main-admin-fmonds-edit" */ './views/main/admin/FmondEdit.vue'),
        //         },
                {
                  path: 'rules',
                  redirect: 'rules/all',
                },
                {
                  path: 'rules/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-rules" */ './views/main/admin/RulesList.vue'),
                },
                {
                  name: 'main-admin-rules-create',
                  path: 'rules/create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-rules-create" */ './views/main/admin/RulesCreate.vue'),
                },
                {
                  path: 'rules/edit/:ruleId',
                  name: 'main-admin-rules-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-rules-edit" */ './views/main/admin/RulesEdit.vue'),
                  props: route => {
                    return {
                      ruleId: parseInt(route.params.ruleId)
                    }
                  }
                },
        //         {
        //           path: 'status-mappings',
        //           redirect: 'status-mappings/all',
        //         },
        //         {
        //           path: 'status-mappings/all',
        //           component: () => import(
        //             /* webpackChunkName: "main-admin-status-mappings" */ './views/main/admin/StatusMappingList.vue'),
        //         },
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users" */ './views/main/admin/UsersList.vue'),
                },
                {
                  path: 'users/edit/:userId',
                  name: 'main-admin-users-edit',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-edit" */ './views/main/admin/UsersEdit.vue'),
                  props: route => { return { userId: parseInt(route.params.userId) } }
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                    /* webpackChunkName: "main-admin-users-create" */ './views/main/admin/UsersCreate.vue'),
                },
                {
                  path: 'device-types',
                  redirect: 'device-types/all',
                },
                {
                  path: 'device-types/all',
                  component: () => import(
                      /* webpackChunkName: "main-admin-device-types" */ './views/main/admin/DeviceTypeList.vue'),
                },
                /*{
                  path: 'device-types/create',
                  name: 'main-admin-device-types-create',
                  component: () => import(
                      /!* webpackChunkName: "main-admin-device-types-create" *!/ './views/main/admin/DeviceTypeCreate.vue'),
                },*/
                {
                  path: 'device-types/:deviceTypeId',
                  name: 'main-admin-device-types-view',
                  component: () => import(
                    /* webpackChunkName: "main-admin-device-type-view" */ './views/main/admin/DeviceTypeView.vue'
                  ),
                  props: route => {
                    return {
                      deviceTypeId: parseInt(route.params.deviceTypeId)
                    }
                  }
                },
                {
                  path: 'device-types/edit/:deviceTypeId',
                  name: 'main-admin-device-types-edit',
                  component: () => import(
                      /*webpackChunkName: "main-admin-device-type-edit" */ './views/main/admin/DeviceTypeEdit.vue'),
                  props: route => { return { deviceTypeId: parseInt(route.params.deviceTypeId) } }
                },
        //         {
        //           path: 'policy',
        //           name: 'main-admin-policy-edit',
        //           component: () => import(
        //             /* webpackChunkName: "main-admin-policy-edit" */ './views/main/admin/PolicyEdit.vue'),
        //         },
        //         {
        //           path: 'profiler',
        //           name: 'main-admin-profiler',
        //           component: () => import(
        //             /* webpackChunkName: "main-admin-profiler" */ './views/main/admin/DatabaseProfiler.vue'),
        //         },
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ],
});
