import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {DeviceStateApi} from "@/api";

export interface DeviceStatesState {}
const defaultState: DeviceStatesState = {}

type Context = ActionContext<DeviceStatesState, RootState>;

export const deviceStates: Module<DeviceStatesState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async getByDevice(context: Context, payload: { deviceId: number, page: number|undefined, itemsPerPage: number|undefined }) {
            const api: DeviceStateApi = context.rootGetters['api/deviceStateApi'];

            return (await api.getDeviceStateCollection(
                payload.page,
                payload.itemsPerPage,
                payload.deviceId,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                'desc'
            )).data;
        }
    }
}
