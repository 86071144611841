import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {
    CreateDeviceTypeDTO,
    DeviceTypeApi,
    UpdateDeviceTypeDTO,
} from "@/api";

export interface DeviceTypeState {}
const defaultState: DeviceTypeState = {}

type Context = ActionContext<DeviceTypeState, RootState>;

export const deviceTypes: Module<DeviceTypeState, RootState> = {

    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { deviceTypeId: number }) {
            const api: DeviceTypeApi = context.rootGetters['api/deviceTypeApi'];

            return (await api.getDeviceTypeItem(payload.deviceTypeId)).data;
        },
        async getAll(context: Context, payload) {
            const api: DeviceTypeApi = context.rootGetters['api/deviceTypeApi'];

            return (await api.getDeviceTypeCollection()).data;
        },
        async getStatusMappings(context: Context, payload: { deviceTypeId: number }) {
            const api: DeviceTypeApi = context.rootGetters['api/deviceTypeApi'];

            return (await api.getDeviceTypeStatusMappings(payload.deviceTypeId)).data;
        },
        async create(context: Context, payload: { data: CreateDeviceTypeDTO }) {
            const api: DeviceTypeApi = context.rootGetters['api/deviceTypeApi'];

            return api.postDeviceTypeCollection(payload.data);
        },
        async remove(context: Context, payload: { deviceTypeId: number }) {
            const api: DeviceTypeApi = context.rootGetters['api/deviceTypeApi'];

            return api.deleteDeviceTypeItem(payload.deviceTypeId);
        },
        async update(context: Context, payload: { deviceTypeId: number, data: UpdateDeviceTypeDTO }) {
            const api: DeviceTypeApi = context.rootGetters['api/deviceTypeApi'];

            return await api.putDeviceTypeItem(payload.deviceTypeId, payload.data);
        },
    }
}

