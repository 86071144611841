import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {
    AuthenticationApi,
    AuthenticationApiFactory,
    Configuration,
    DeviceApiFactory,
    DeviceConfigurationApiFactory,
    DeviceEventApiFactory,
    DeviceStateApiFactory,
    DeviceStatusMappingApiFactory,
    DeviceTypeApiFactory,
    FmonDaemonApiFactory,
    FmonDaemonRegistrationRequestApiFactory,
    LocationApiFactory,
    OrganizationApiFactory,
    RuleApiFactory,
    RuleTriggerApiFactory,
    UserApiFactory
} from "@/api";
import UserInfo from "@/types/UserInfo";
import {AxiosError} from "axios";
import ApiRole from "@/types/ApiRole";
import {apiBaseUrl} from "@/env";

export interface ApiState {
    userInfo: UserInfo|null,
    config: Configuration
}

const defaultState: ApiState = {
    userInfo: null,
    config: new Configuration()
}

type Context = ActionContext<ApiState, RootState>;

export const api: Module<ApiState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {
        userInfo: state => state.userInfo,
        isLoggedIn: state => state.userInfo !== null,

        isSuperAdmin: state => state.userInfo?.roles.includes(ApiRole.ROLE_SUPER_ADMIN),
        isTechSupport: state => state.userInfo?.roles.includes(ApiRole.ROLE_TECHNICAL_SUPPORT),
        isOrgAdmin: state => state.userInfo?.roles.includes(ApiRole.ROLE_ORGANIZATION_ADMIN),
        isLocationAdmin: state => state.userInfo?.roles.includes(ApiRole.ROLE_LOCATION_ADMIN),

        superAdminGranted: state => {
            return state.userInfo?.roles.includes(ApiRole.ROLE_SUPER_ADMIN)
        },
        techSupportGranted: state => {
            return state.userInfo?.roles.includes(ApiRole.ROLE_SUPER_ADMIN) ||
                state.userInfo?.roles.includes(ApiRole.ROLE_TECHNICAL_SUPPORT)
        },
        orgAdminGranted: state => {
            return state.userInfo?.roles.includes(ApiRole.ROLE_SUPER_ADMIN) ||
                state.userInfo?.roles.includes(ApiRole.ROLE_TECHNICAL_SUPPORT) ||
                state.userInfo?.roles.includes(ApiRole.ROLE_ORGANIZATION_ADMIN)
        },
        locationAdminGranted: state => {
            return state.userInfo?.roles.includes(ApiRole.ROLE_SUPER_ADMIN) ||
                state.userInfo?.roles.includes(ApiRole.ROLE_TECHNICAL_SUPPORT) ||
                state.userInfo?.roles.includes(ApiRole.ROLE_ORGANIZATION_ADMIN) ||
                state.userInfo?.roles.includes(ApiRole.ROLE_LOCATION_ADMIN)
        },

        // Wrappers for api factories
        authApi: state => AuthenticationApiFactory(state.config, apiBaseUrl),
        organizationApi: state => OrganizationApiFactory(state.config, apiBaseUrl),
        userApi: state => UserApiFactory(state.config, apiBaseUrl),
        locationApi: state => LocationApiFactory(state.config, apiBaseUrl),
        fmonDaemonApi: state => FmonDaemonApiFactory(state.config, apiBaseUrl),
        fmonDaemonRegistrationRequestApi: state => FmonDaemonRegistrationRequestApiFactory(state.config, apiBaseUrl),
        deviceApi: state => DeviceApiFactory(state.config, apiBaseUrl),
        deviceStateApi: state => DeviceStateApiFactory(state.config, apiBaseUrl),
        deviceEventApi: state => DeviceEventApiFactory(state.config, apiBaseUrl),
        deviceConfigurationApi: state => DeviceConfigurationApiFactory(state.config, apiBaseUrl),
        deviceTypeApi: state => DeviceTypeApiFactory(state.config, apiBaseUrl),
        deviceStatusMappingApi: state => DeviceStatusMappingApiFactory(state.config, apiBaseUrl),
        ruleApi: state => RuleApiFactory(state.config, apiBaseUrl),
        ruleTriggerApi: state => RuleTriggerApiFactory(state.config, apiBaseUrl)
    },
    mutations: {
        setUserInfo(state, newUserInfo: UserInfo|null) {
            state.userInfo = newUserInfo;

            // Also commit user to local storage
            if (!newUserInfo) {
                localStorage.removeItem('user_info')
            } else {
                localStorage.setItem('user_info', JSON.stringify(newUserInfo));
            }
        }
    },
    actions: {
        async getUserFromLocalStorage(context: Context) {
          const data = localStorage.getItem('user_info');

          if (data === null) {
              context.commit('setUserInfo', null);
          } else {
              const user: UserInfo = JSON.parse(data);
              context.commit('setUserInfo', user);
          }
        },
        async logIn(context: Context, payload: { username: string, password: string }) {
            const authApi: AuthenticationApi = context.getters.authApi;

            return authApi.postCredentialsItem(
                {
                    username: payload.username,
                    password: payload.password
                }
            ).then(
                response => {
                    context.commit(
                        'setUserInfo',
                        {
                            username: response.data.username,
                            roles: response.data.roles,
                            organizationId: response.data.organization_id,
                            organizationName: response.data.organization_name,
                            firstName: response.data.first_name,
                            lastName: response.data.last_name
                        } as UserInfo
                    );
                    context.commit('ui/addSuccessNotification', 'Login success.', { root: true })
                }
            ).catch(
                (error: AxiosError) => {
                    console.log(error)
                    let errorMessage = '';
                    console.log(error.response)
                    if (error.response?.status === 401) {
                        errorMessage = 'Login failed: incorrect username or password.';
                    } else if (error.response) {
                        errorMessage = 'Something went wrong';
                    } else {
                        errorMessage = 'The server could not be reached';
                    }
                    context.commit('ui/addErrorNotification', errorMessage, { root: true });
                }
            )
        },
        async logOut(context: Context) {
            context.commit('setUserInfo', null);
        },
        async handleApiError(context: Context, payload: AxiosError) {
            if (payload.response!.status === 401) {
                await context.dispatch('api/logOut');
                await context.dispatch('ui/navigateToLogin', { root: true })
            } else {
                // TODO: make more specific
                context.commit(
                    'ui/addNotification',
                    { content: 'Something went wrong', color: 'error' },
                    { root: true }
                );
            }
        }
    }
}
