import globalAxios, {AxiosError} from "axios";
import router from "@/router";
import store from "@/store";
import any = jasmine.any;

async function onErrorResponse(error: AxiosError | any) {
    if (error.response?.status === 401 && router.currentRoute.name !== 'login') {
        // Redirect to login in case the JWT has expired.
        await store.dispatch('api/logOut')
        await router.push('login')
        await store.commit('ui/addErrorNotification', 'Login has expired.')
    }
    if (error.response.data && error.response.data.detail !==undefined) {
        await store.commit('ui/addErrorNotification', error.response.data.detail)
    }
    return Promise.reject(error);
}

export default function configureAxios() {
    globalAxios.defaults.withCredentials = true;
    globalAxios.interceptors.response.use(x => x, onErrorResponse);
}
