import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {CreateRuleDTO, RuleApi, UpdateRuleDTO} from "@/api";

export interface RulesState {}
const defaultState: RulesState = {};

type Context = ActionContext<RulesState, RootState>;

export const rules: Module<RulesState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { ruleId: number }) {
            const api: RuleApi = context.rootGetters['api/ruleApi'];

            return (await api.getRuleItem(payload.ruleId)).data;
        },
        async getAll(context: Context, payload) {
            const api: RuleApi = context.rootGetters['api/ruleApi'];

            return (await api.getRuleCollection()).data;
        },
        async create(context: Context, payload: { data: CreateRuleDTO }) {
            const api: RuleApi = context.rootGetters['api/ruleApi'];

            return (await api.postRuleCollection(payload.data));
        },
        async update(context: Context, payload: { ruleId: number, data: UpdateRuleDTO}) {
            const api: RuleApi = context.rootGetters['api/ruleApi'];

            return await api.putRuleItem(payload.ruleId, payload.data);
        },
        async delete(context: Context, payload: { ruleId: number }) {
            const api: RuleApi = context.rootGetters['api/ruleApi'];

            return await api.deleteRuleItem(payload.ruleId);
        }
    }
}
