import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {CreateDeviceStatusMappingDTO, DeviceStatusMappingApi} from "@/api";

export interface DeviceStatusMappingsState {}
const defaultState: DeviceStatusMappingsState = {}

type Context = ActionContext<DeviceStatusMappingsState, RootState>;

export const deviceStatusMappings: Module<DeviceStatusMappingsState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async create(context: Context, payload: { data: CreateDeviceStatusMappingDTO }) {
            const api: DeviceStatusMappingApi = context.rootGetters['api/deviceStatusMappingApi'];

            return (await api.postDeviceStatusMappingCollection(payload.data)).data;
        },
        async delete(context: Context, payload: { deviceStatusMappingId: number }) {
            const api: DeviceStatusMappingApi = context.rootGetters['api/deviceStatusMappingApi'];

            return await api.deleteDeviceStatusMappingItem(payload.deviceStatusMappingId);
        }
    }
}