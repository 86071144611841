import Vue from 'vue';
import Vuex from 'vuex';

import {api, ApiState} from "./modules/api";
import {ui, UiState} from "@/store/modules/ui";
import {organizations, OrganizationsState} from "@/store/modules/organizations";
import {users, UsersState} from "@/store/modules/users";
import {locations, LocationsState} from "@/store/modules/locations";
import {devices, DevicesState} from "@/store/modules/devices";
import {deviceStates, DeviceStatesState} from "@/store/modules/deviceStates";
import {deviceEvents, DeviceEventsState} from "@/store/modules/deviceEvents";
import {ruleTriggers, RuleTriggersState} from "@/store/modules/ruleTriggers";
import {rules, RulesState} from "@/store/modules/rules";
import {deviceTypes, DeviceTypeState} from "@/store/modules/deviceTypes";
import {deviceStatusMappings, DeviceStatusMappingsState} from "@/store/modules/deviceStatusMappings";
import {fmonDaemonRegistrationRequests, FmonDaemonRegistrationRequestsState} from "@/store/modules/fmonDaemonRegistrationRequests";

Vue.use(Vuex);

export interface RootState {
  api: ApiState,
  ui: UiState,
  organizations: OrganizationsState,
  users: UsersState,
  locations: LocationsState
  devices: DevicesState
  deviceStates: DeviceStatesState,
  deviceEvents: DeviceEventsState,
  ruleTriggers: RuleTriggersState,
  rules: RulesState
  deviceTypes: DeviceTypeState,
  deviceStatusMappings: DeviceStatusMappingsState,
  fmonDaemonRegistrationRequests: FmonDaemonRegistrationRequestsState
}

export const store = new Vuex.Store<RootState>({
  modules: {
    api,
    ui,
    organizations,
    users,
    locations,
    devices,
    deviceStates,
    deviceEvents,
    ruleTriggers,
    rules,
    deviceTypes,
    deviceStatusMappings,
    fmonDaemonRegistrationRequests
  },
});

export default store;
