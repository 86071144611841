import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {CreateUserDTO, UpdateUserDTO, UserApi} from "@/api";

export interface UsersState {}
const defaultState: UsersState = {}

type Context = ActionContext<UsersState, RootState>

export const users: Module<UsersState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { userId: number }) {
            const api: UserApi = context.rootGetters['api/userApi'];

            return (await api.getUserItem(payload.userId)).data;
        },
        async getAll(context: Context, payload) {
            const api: UserApi = context.rootGetters['api/userApi'];

            return (await api.getUserCollection()).data;
        },
        async create(context: Context, payload: { data: CreateUserDTO }) {
            const api: UserApi = context.rootGetters['api/userApi'];

            return (await api.postUserCollection(payload.data)).data;
        },
        async update(context: Context, payload: { userId: number, data: UpdateUserDTO }) {
            const api: UserApi = context.rootGetters['api/userApi'];

            return api.putUserItem(payload.userId, payload.data);
        },
        async remove(context: Context, payload: { userId: number }) {
            const api: UserApi = context.rootGetters['api/userApi'];

            return api.deleteUserItem(payload.userId);
        }
    }
}
