import {ActionContext, Module} from "vuex";
import {OrganizationsState} from "@/store/modules/organizations";
import {RootState} from "@/store";
import {CreateLocationDTO, LocationApi, OrganizationApi, UpdateLocationDTO} from "@/api";

export interface LocationsState {}
const defaultState: LocationsState = {}

type Context = ActionContext<OrganizationsState, RootState>;

export const locations: Module<LocationsState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { locationId: number }) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            return (await api.getLocationItem(payload.locationId)).data;
        },
        async getByOrganization(context: Context, payload: { organizationId: number }) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            return (await api.getOrganizationLocations(payload.organizationId)).data;
        },
        async getAll(context: Context, payload) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            return (await api.getLocationCollection()).data;
        },
        async getDevices(context: Context, payload: { locationId: number }) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            return (await api.getLocationDevicesCollection(payload.locationId)).data;
        },
        async getFmonDaemon(context: Context, payload: { locationId: number }) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            // Only retrieve the first FMON Daemon as only one is probably active. Note that ordering by
            // last_communication DESC we probably take the 'relevant' daemon instead of one that is out of use.

            let result = (await api.getLocationFmonDaemonsCollection(
                payload.locationId,
                undefined,
                undefined,
                "desc"
            )).data;

            if (result.length >= 1) {
                return result[0];
            } else {
                return null;
            }
        },
        async create(context: Context, payload: { data: CreateLocationDTO }) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            return (await api.postLocationCollection(payload.data)).data;
        },
        async update(context: Context, payload: { locationId: number, data: UpdateLocationDTO }) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            return await api.putLocationItem(payload.locationId, payload.data);
        },
        async remove(context: Context, payload: { locationId: number }) {
            const api: LocationApi = context.rootGetters['api/locationApi'];

            return await api.deleteLocationItem(payload.locationId);
        }
    }
}
