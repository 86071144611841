import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {CreateOrganizationDTO, OrganizationApi, UpdateOrganizationDTO} from "@/api";

export interface OrganizationsState {}
const defaultState: OrganizationsState = {}

type Context = ActionContext<OrganizationsState, RootState>;

export const organizations: Module<OrganizationsState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { organizationId: number }) {
            const api: OrganizationApi = context.rootGetters['api/organizationApi'];

            return (await api.getOrganizationItem(payload.organizationId)).data;
        },
        async getAll(context: Context, payload) {
            const api: OrganizationApi = context.rootGetters['api/organizationApi'];

            return (await api.getOrganizationCollection()).data;
        },
        async getLocations(context: Context, payload: { organizationId: number }) {
            const api: OrganizationApi = context.rootGetters['api/organizationApi'];

            return (await api.getOrganizationLocations(payload.organizationId)).data;
        },
        async create(context: Context, payload: { data: CreateOrganizationDTO }) {
            const api: OrganizationApi = context.rootGetters['api/organizationApi'];

            return api.postOrganizationCollection(payload.data);
        },
        async update(context: Context, payload: { organizationId: number, data: UpdateOrganizationDTO }) {
            const api: OrganizationApi = context.rootGetters['api/organizationApi'];

            return api.putOrganizationItem(payload.organizationId, payload.data);
        },
        async remove(context: Context, payload: { organizationId: number }) {
            const api: OrganizationApi = context.rootGetters['api/organizationApi'];

            return api.deleteOrganizationItem(payload.organizationId);
        }
    }
}
