enum ApiRole {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_TECHNICAL_SUPPORT = 'ROLE_TECHNICAL_SUPPORT',
  ROLE_ORGANIZATION_ADMIN = 'ROLE_ORGANIZATION_ADMIN',
  ROLE_LOCATION_ADMIN = 'ROLE_LOCATION_ADMIN',
}

export default ApiRole

export function formatApiRole(role: ApiRole | string) {
  if (ApiRole[role]) {
    role = ApiRole[role]
  }

  switch (role) {
    case ApiRole.ROLE_SUPER_ADMIN.valueOf():
      return 'Super Admin'
    case ApiRole.ROLE_TECHNICAL_SUPPORT.valueOf():
      return 'Technical Support'
    case ApiRole.ROLE_ORGANIZATION_ADMIN.valueOf():
      return 'Organization Admin'
    case ApiRole.ROLE_LOCATION_ADMIN.valueOf():
      return 'Location Admin'
  }

  return role
}
