import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {DeviceApi, UpdateDeviceDTO} from "@/api";

export interface DevicesState {}
const defaultState: DevicesState = {}

type Context = ActionContext<DevicesState, RootState>;

export const devices: Module<DevicesState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { deviceId: number }) {
            const api: DeviceApi = context.rootGetters['api/deviceApi'];

            return (await api.getDeviceItem(payload.deviceId)).data;
        },
        async getAll(context: Context, payload) {
            const api: DeviceApi = context.rootGetters['api/deviceApi'];

            return (await api.getDeviceCollection()).data;
        },
        async getChildDevices(context: Context, payload: { deviceId: number }) {
            const api: DeviceApi = context.rootGetters['api/deviceApi'];

            return (await api.getDeviceChildDevices(payload.deviceId)).data;
        },
        async update(context: Context, payload: { deviceId: number, data: UpdateDeviceDTO }) {
            const api: DeviceApi = context.rootGetters['api/deviceApi'];

            return (await api.putDeviceItem(payload.deviceId, payload.data)).data;
        },
        async remove(context: Context, payload: { deviceId: number }) {
            const api: DeviceApi = context.rootGetters['api/deviceApi'];

            return (await api.deleteDeviceItem(payload.deviceId));
        },
    }
}