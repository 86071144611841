import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {DeviceEventApi} from "@/api";

export interface DeviceEventsState {}
const defaultState: DeviceEventsState = {};

type Context = ActionContext<DeviceEventsState, RootState>;

interface GetCollectionParams {
    page: number|undefined,
    itemsPerPage: number|undefined,
    deviceId: number|undefined,
    locationId: number|undefined,
    organizationId: number|undefined
    timestampBefore: string|undefined,
    timestampAfter: string|undefined,
    orderTimestamp: "asc"|"desc"|undefined
}

export const deviceEvents: Module<DeviceEventsState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { deviceEventId: number }) {
            const api: DeviceEventApi = context.rootGetters['api/deviceEventApi'];

            return (await api.getDeviceEventItem(payload.deviceEventId)).data;
        },
        async getAll(context: Context, payload: GetCollectionParams|undefined) {
            const api: DeviceEventApi = context.rootGetters['api/deviceEventApi'];

            if (!payload) {
                payload = {} as GetCollectionParams;
            }

            return (await api.getDeviceEventCollection(
                payload.page,
                payload.itemsPerPage,
                payload.deviceId,
                undefined,
                payload.locationId,
                undefined,
                payload.organizationId,
                undefined,
                payload.timestampBefore,
                undefined,
                payload.timestampAfter,
                undefined,
                payload.orderTimestamp ?? "desc"
            )).data;
        },
        async getByDevice(context: Context, payload: { deviceId: number }) {
            return await context.dispatch('getAll',  { deviceId: payload.deviceId })
        },
        async getByLocation(context: Context, payload: { locationId: number }) {
            return await context.dispatch('getAll',  { locationId: payload.locationId })
        },
        async getByOrganization(context: Context, payload: { organizationId: number }) {
            return await context.dispatch('getAll', { organizationId: payload.organizationId })
        }
    }
}
