













import { Vue, Component, Watch } from 'vue-property-decorator';
import AppNotification from "@/types/AppNotifcation";
import {mapGetters} from "vuex";

@Component(
    {
      computed: mapGetters({
        notifications: 'ui/notifications'
      })
    }
)
export default class NotificationsManager extends Vue {
  private static NOTIFICATION_TIMEOUT = 5000;

  public show: boolean = false;
  public text: string = '';
  public showProgress: boolean = false;
  public currentNotification: AppNotification | false = false;
  private notifications!: AppNotification[];

  get firstNotification(): AppNotification|null {
    return this.notifications[0] ?? null;
  }

  public async hide(notification: AppNotification | null = null) {
    if (notification && notification !== this.currentNotification) {
      // A new notification might have popped up
      return;
    }

    this.show = false;
    await new Promise((resolve, reject) => setTimeout(() => resolve(), 500));
  }

  public async close() {
    await this.hide();
  }

  public async setNotification(notification: AppNotification | false) {
    if (this.show) {
      await this.hide();
    }
    if (notification) {
      this.currentNotification = notification;
      this.showProgress = notification.showProgress || false;
      this.show = true;
      this.$store.commit('ui/removeNotification', notification);
    } else {
      this.currentNotification = false;
    }
  }

  @Watch('firstNotification')
  public async onNotificationChange(newNotification: AppNotification | false) {
    if (!newNotification) {
      return;
    }

    if (newNotification !== this.currentNotification) {
      await this.setNotification(newNotification);

      if (newNotification) {
        setTimeout(() => this.hide(newNotification), NotificationsManager.NOTIFICATION_TIMEOUT)
      }
    }
  }

  public get currentNotificationContent() {
    return (this.currentNotification && this.currentNotification.content) || '';
  }

  public get currentNotificationColor() {
    return (
      (this.currentNotification && this.currentNotification.color) || 'info'
    );
  }
}
