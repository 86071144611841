import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {
    FmonDaemonRegistrationRequestApi,
    OutputFmonDaemonRegistrationRequestDTO,
    UpdateFmonDaemonRegistrationRequestDTO
} from "@/api";
import FmonDaemonRegistrationRequestStatus from "@/types/FmonDaemonRegistrationRequestStatus";

export interface FmonDaemonRegistrationRequestsState {}
const defaultState: FmonDaemonRegistrationRequestsState = {}

type Context = ActionContext<FmonDaemonRegistrationRequestsState, RootState>;

export const fmonDaemonRegistrationRequests: Module<FmonDaemonRegistrationRequestsState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { requestId: number }) {
            const api: FmonDaemonRegistrationRequestApi = context.rootGetters['api/fmonDaemonRegistrationRequestApi'];

            return (await api.getFmonDaemonRegistrationRequestItem(payload.requestId)).data;
        },
        async getAll(context: Context, payload: { page: number|undefined, itemsPerPage: number|undefined, cleared: boolean|undefined, status: FmonDaemonRegistrationRequestStatus|undefined }) {
            const api: FmonDaemonRegistrationRequestApi = context.rootGetters['api/fmonDaemonRegistrationRequestApi'];

            return (await api.getFmonDaemonRegistrationRequestCollection(
                payload.page,
                payload.itemsPerPage,
                payload.cleared,
                payload.status
            )).data;
        },
        async clear(context: Context, payload: { requestId: number }) {
            const api: FmonDaemonRegistrationRequestApi = context.rootGetters['api/fmonDaemonRegistrationRequestApi'];

            await api.clearFmonDaemonRegistrationRequestItem(payload.requestId, {});
        },
        async approve(context: Context, payload: { requestId: number, locationId: number }) {
            const api: FmonDaemonRegistrationRequestApi = context.rootGetters['api/fmonDaemonRegistrationRequestApi'];

            await api.putFmonDaemonRegistrationRequestItem(
                payload.requestId,
                {
                    approved: true,
                    location_id: payload.locationId
                }
            );
        },
        async reject(context: Context, payload: { requestId: number }) {
            const api: FmonDaemonRegistrationRequestApi = context.rootGetters['api/fmonDaemonRegistrationRequestApi'];

            await api.putFmonDaemonRegistrationRequestItem(
                payload.requestId,
                {
                    approved: false,
                    location_id: null
                }
            );
        }
    }
}
