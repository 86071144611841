import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import router from "@/router";
import SnackbarNotification from "@/types/AppNotifcation";
import AppNotification from "@/types/AppNotifcation";

export interface UiState {
    notifications: AppNotification[];
}

const defaultState: UiState = {
    notifications: []
}

type Context = ActionContext<UiState, RootState>;

export const ui: Module<UiState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {
        notifications: state => state.notifications
    },
    mutations: {
        addNotification(state: UiState, payload: SnackbarNotification) {
            state.notifications.push(payload);
        },
        removeNotification(state: UiState, payload: SnackbarNotification) {
            state.notifications = state.notifications.filter((notification) => notification !== payload);
        },
        addSuccessNotification(state: UiState, payload: string) {
            state.notifications.push({ content: payload, color: 'green' });
        },
        addErrorNotification(state: UiState, payload: string) {
            state.notifications.push({ content: payload, color: 'red' })
        }
    },
    actions: {
        navigateToLogin(context: Context) {
            if (router.currentRoute.path !== '/login') {
                router.push('/login');
            }
        }
    }
}
