/* tslint:disable */
/* eslint-disable */
/**
 * 
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Authentication
 */
export interface Authentication {
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'username'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Authentication
     */
    'roles'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'organization_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof Authentication
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Authentication
     */
    'last_name'?: string;
}
/**
 * Class DeviceStatusMapping
 * @export
 * @interface CreateDeviceStatusMappingDTO
 */
export interface CreateDeviceStatusMappingDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateDeviceStatusMappingDTO
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceStatusMappingDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceStatusMappingDTO
     */
    'external_status'?: string;
}
/**
 * Class DeviceStatusMapping
 * @export
 * @interface CreateDeviceStatusMappingDTOJsonld
 */
export interface CreateDeviceStatusMappingDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof CreateDeviceStatusMappingDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceStatusMappingDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceStatusMappingDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateDeviceStatusMappingDTOJsonld
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceStatusMappingDTOJsonld
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceStatusMappingDTOJsonld
     */
    'external_status'?: string;
}
/**
 * 
 * @export
 * @interface CreateDeviceTypeDTO
 */
export interface CreateDeviceTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceTypeDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceTypeDTO
     */
    'external_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateDeviceTypeDTOJsonld
 */
export interface CreateDeviceTypeDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof CreateDeviceTypeDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceTypeDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceTypeDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceTypeDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreateDeviceTypeDTOJsonld
     */
    'external_id'?: string;
}
/**
 * 
 * @export
 * @interface CreateLocationDTO
 */
export interface CreateLocationDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateLocationDTO
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDTO
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateLocationDTO
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof CreateLocationDTO
     */
    'latitude': number;
}
/**
 * 
 * @export
 * @interface CreateLocationDTOJsonld
 */
export interface CreateLocationDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof CreateLocationDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateLocationDTOJsonld
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateLocationDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof CreateLocationDTOJsonld
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof CreateLocationDTOJsonld
     */
    'latitude': number;
}
/**
 * Class Organization
 * @export
 * @interface CreateOrganizationDTO
 */
export interface CreateOrganizationDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDTO
     */
    'name': string;
}
/**
 * Class Organization
 * @export
 * @interface CreateOrganizationDTOJsonld
 */
export interface CreateOrganizationDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof CreateOrganizationDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateOrganizationDTOJsonld
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface CreateRuleDTO
 */
export interface CreateRuleDTO {
    /**
     * 
     * @type {number}
     * @memberof CreateRuleDTO
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRuleDTO
     */
    'rule_data'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTO
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTO
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTO
     */
    'message_type'?: string;
}
/**
 * 
 * @export
 * @interface CreateRuleDTOJsonld
 */
export interface CreateRuleDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof CreateRuleDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof CreateRuleDTOJsonld
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateRuleDTOJsonld
     */
    'rule_data'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTOJsonld
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTOJsonld
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof CreateRuleDTOJsonld
     */
    'message_type'?: string;
}
/**
 * Class User
 * @export
 * @interface CreateUserDTO
 */
export interface CreateUserDTO {
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserDTO
     */
    'organization_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTO
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserDTO
     */
    'roles': Array<string>;
}
/**
 * Class User
 * @export
 * @interface CreateUserDTOJsonld
 */
export interface CreateUserDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof CreateUserDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTOJsonld
     */
    'username': string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTOJsonld
     */
    'password': string;
    /**
     * 
     * @type {number}
     * @memberof CreateUserDTOJsonld
     */
    'organization_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTOJsonld
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTOJsonld
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateUserDTOJsonld
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreateUserDTOJsonld
     */
    'roles': Array<string>;
}
/**
 * 
 * @export
 * @interface Credentials
 */
export interface Credentials {
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof Credentials
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface FmonDaemonInputFmondCertRequestDTO
 */
export interface FmonDaemonInputFmondCertRequestDTO {
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonInputFmondCertRequestDTO
     */
    'csr': string;
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonInputFmondCertRequestDTO
     */
    'fmond_id'?: string;
}
/**
 * 
 * @export
 * @interface FmonDaemonInputFmondRegistrationDTO
 */
export interface FmonDaemonInputFmondRegistrationDTO {
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonInputFmondRegistrationDTO
     */
    'csr': string;
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonInputFmondRegistrationDTO
     */
    'fmond_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonInputFmondRegistrationDTO
     */
    'balena_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonInputFmondRegistrationDTO
     */
    'enrollment_token'?: string;
}
/**
 * 
 * @export
 * @interface FmonDaemonRegistrationRequestEmptyDTOJsonld
 */
export interface FmonDaemonRegistrationRequestEmptyDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof FmonDaemonRegistrationRequestEmptyDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonRegistrationRequestEmptyDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof FmonDaemonRegistrationRequestEmptyDTOJsonld
     */
    '@type'?: string;
}
/**
 * 
 * @export
 * @interface GetDeviceConfigurationCollection200Response
 */
export interface GetDeviceConfigurationCollection200Response {
    /**
     * 
     * @type {Array<OutputDeviceConfigurationDTOJsonld>}
     * @memberof GetDeviceConfigurationCollection200Response
     */
    'hydra:member': Array<OutputDeviceConfigurationDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetDeviceConfigurationCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetDeviceConfigurationCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetDeviceConfigurationCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetDeviceConfigurationCollection200ResponseHydraSearch
 */
export interface GetDeviceConfigurationCollection200ResponseHydraSearch {
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearch
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearch
     */
    'hydra:template'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearch
     */
    'hydra:variableRepresentation'?: string;
    /**
     * 
     * @type {Array<GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner>}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearch
     */
    'hydra:mapping'?: Array<GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner>;
}
/**
 * 
 * @export
 * @interface GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner
 */
export interface GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner {
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner
     */
    'variable'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner
     */
    'property'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraSearchHydraMappingInner
     */
    'required'?: boolean;
}
/**
 * 
 * @export
 * @interface GetDeviceConfigurationCollection200ResponseHydraView
 */
export interface GetDeviceConfigurationCollection200ResponseHydraView {
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraView
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraView
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraView
     */
    'hydra:first'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraView
     */
    'hydra:last'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraView
     */
    'hydra:previous'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeviceConfigurationCollection200ResponseHydraView
     */
    'hydra:next'?: string;
}
/**
 * 
 * @export
 * @interface GetDeviceEventCollection200Response
 */
export interface GetDeviceEventCollection200Response {
    /**
     * 
     * @type {Array<OutputDeviceEventDTOJsonld>}
     * @memberof GetDeviceEventCollection200Response
     */
    'hydra:member': Array<OutputDeviceEventDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetDeviceEventCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetDeviceEventCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetDeviceEventCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetDeviceStateCollection200Response
 */
export interface GetDeviceStateCollection200Response {
    /**
     * 
     * @type {Array<OutputDeviceStateDTOJsonld>}
     * @memberof GetDeviceStateCollection200Response
     */
    'hydra:member': Array<OutputDeviceStateDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetDeviceStateCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetDeviceStateCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetDeviceStateCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetDeviceStatusMappingCollection200Response
 */
export interface GetDeviceStatusMappingCollection200Response {
    /**
     * 
     * @type {Array<OutputDeviceStatusMappingDTOJsonld>}
     * @memberof GetDeviceStatusMappingCollection200Response
     */
    'hydra:member': Array<OutputDeviceStatusMappingDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetDeviceStatusMappingCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetDeviceStatusMappingCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetDeviceStatusMappingCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetDeviceTypeCollection200Response
 */
export interface GetDeviceTypeCollection200Response {
    /**
     * 
     * @type {Array<OutputDeviceTypeDTOJsonld>}
     * @memberof GetDeviceTypeCollection200Response
     */
    'hydra:member': Array<OutputDeviceTypeDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetDeviceTypeCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetDeviceTypeCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetDeviceTypeCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetDeviceTypeDevices200Response
 */
export interface GetDeviceTypeDevices200Response {
    /**
     * 
     * @type {Array<OutputDeviceDTOJsonld>}
     * @memberof GetDeviceTypeDevices200Response
     */
    'hydra:member': Array<OutputDeviceDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetDeviceTypeDevices200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetDeviceTypeDevices200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetDeviceTypeDevices200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetDeviceTypeRules200Response
 */
export interface GetDeviceTypeRules200Response {
    /**
     * 
     * @type {Array<OutputRuleDTOJsonld>}
     * @memberof GetDeviceTypeRules200Response
     */
    'hydra:member': Array<OutputRuleDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetDeviceTypeRules200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetDeviceTypeRules200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetDeviceTypeRules200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFmonDaemonCollection200Response
 */
export interface GetFmonDaemonCollection200Response {
    /**
     * 
     * @type {Array<OutputFmonDaemonDTOJsonld>}
     * @memberof GetFmonDaemonCollection200Response
     */
    'hydra:member': Array<OutputFmonDaemonDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetFmonDaemonCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetFmonDaemonCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetFmonDaemonCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetFmonDaemonRegistrationRequestCollection200Response
 */
export interface GetFmonDaemonRegistrationRequestCollection200Response {
    /**
     * 
     * @type {Array<OutputFmonDaemonRegistrationRequestDTOJsonld>}
     * @memberof GetFmonDaemonRegistrationRequestCollection200Response
     */
    'hydra:member': Array<OutputFmonDaemonRegistrationRequestDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetFmonDaemonRegistrationRequestCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetFmonDaemonRegistrationRequestCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetFmonDaemonRegistrationRequestCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetLocationCollection200Response
 */
export interface GetLocationCollection200Response {
    /**
     * 
     * @type {Array<OutputLocationDTOJsonld>}
     * @memberof GetLocationCollection200Response
     */
    'hydra:member': Array<OutputLocationDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetLocationCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetLocationCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetLocationCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetOrganizationCollection200Response
 */
export interface GetOrganizationCollection200Response {
    /**
     * 
     * @type {Array<OutputOrganizationDTOJsonld>}
     * @memberof GetOrganizationCollection200Response
     */
    'hydra:member': Array<OutputOrganizationDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetOrganizationCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetOrganizationCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetOrganizationCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetOrganizationUsers200Response
 */
export interface GetOrganizationUsers200Response {
    /**
     * 
     * @type {Array<OutputUserDTOJsonld>}
     * @memberof GetOrganizationUsers200Response
     */
    'hydra:member': Array<OutputUserDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetOrganizationUsers200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetOrganizationUsers200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetOrganizationUsers200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface GetRuleTriggerCollection200Response
 */
export interface GetRuleTriggerCollection200Response {
    /**
     * 
     * @type {Array<OutputRuleTriggerDTOJsonld>}
     * @memberof GetRuleTriggerCollection200Response
     */
    'hydra:member': Array<OutputRuleTriggerDTOJsonld>;
    /**
     * 
     * @type {number}
     * @memberof GetRuleTriggerCollection200Response
     */
    'hydra:totalItems'?: number;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraView}
     * @memberof GetRuleTriggerCollection200Response
     */
    'hydra:view'?: GetDeviceConfigurationCollection200ResponseHydraView;
    /**
     * 
     * @type {GetDeviceConfigurationCollection200ResponseHydraSearch}
     * @memberof GetRuleTriggerCollection200Response
     */
    'hydra:search'?: GetDeviceConfigurationCollection200ResponseHydraSearch;
}
/**
 * 
 * @export
 * @interface OutputDeviceConfigurationDTO
 */
export interface OutputDeviceConfigurationDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceConfigurationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceConfigurationDTO
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceConfigurationDTO
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceConfigurationDTO
     */
    'device_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceConfigurationDTOJsonld
 */
export interface OutputDeviceConfigurationDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceConfigurationDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceConfigurationDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceConfigurationDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceConfigurationDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceConfigurationDTOJsonld
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceConfigurationDTOJsonld
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceConfigurationDTOJsonld
     */
    'device_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceDTO
 */
export interface OutputDeviceDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTO
     */
    'parent_device_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTO
     */
    'location_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTO
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTO
     */
    'last_communication'?: string;
    /**
     * 
     * @type {OutputDeviceTypeDTO}
     * @memberof OutputDeviceDTO
     */
    'device_type'?: OutputDeviceTypeDTO;
    /**
     * 
     * @type {OutputDeviceDTOLastState}
     * @memberof OutputDeviceDTO
     */
    'last_state'?: OutputDeviceDTOLastState | null;
    /**
     * 
     * @type {OutputDeviceDTOLastConfiguration}
     * @memberof OutputDeviceDTO
     */
    'last_configuration'?: OutputDeviceDTOLastConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTO
     */
    'external_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OutputDeviceDTOJsonld
 */
export interface OutputDeviceDTOJsonld {
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonld
     */
    'parent_device_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonld
     */
    'location_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonld
     */
    'organization_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonld
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonld
     */
    'last_communication'?: string;
    /**
     * 
     * @type {OutputDeviceTypeDTOJsonld}
     * @memberof OutputDeviceDTOJsonld
     */
    'device_type'?: OutputDeviceTypeDTOJsonld;
    /**
     * 
     * @type {OutputDeviceDTOJsonldLastState}
     * @memberof OutputDeviceDTOJsonld
     */
    'last_state'?: OutputDeviceDTOJsonldLastState | null;
    /**
     * 
     * @type {OutputDeviceDTOJsonldLastConfiguration}
     * @memberof OutputDeviceDTOJsonld
     */
    'last_configuration'?: OutputDeviceDTOJsonldLastConfiguration | null;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonld
     */
    'external_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonld
     */
    'name': string;
}
/**
 * @type OutputDeviceDTOJsonldContext
 * @export
 */
export type OutputDeviceDTOJsonldContext = OutputDeviceDTOJsonldContextOneOf | string;

/**
 * 
 * @export
 * @interface OutputDeviceDTOJsonldContextOneOf
 */
export interface OutputDeviceDTOJsonldContextOneOf {
    [key: string]: any;

    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldContextOneOf
     */
    '@vocab': string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldContextOneOf
     */
    'hydra': OutputDeviceDTOJsonldContextOneOfHydraEnum;
}

export const OutputDeviceDTOJsonldContextOneOfHydraEnum = {
    HttpWwwW3OrgNsHydraCore: 'http://www.w3.org/ns/hydra/core#'
} as const;

export type OutputDeviceDTOJsonldContextOneOfHydraEnum = typeof OutputDeviceDTOJsonldContextOneOfHydraEnum[keyof typeof OutputDeviceDTOJsonldContextOneOfHydraEnum];

/**
 * 
 * @export
 * @interface OutputDeviceDTOJsonldLastConfiguration
 */
export interface OutputDeviceDTOJsonldLastConfiguration {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceDTOJsonldLastConfiguration
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastConfiguration
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastConfiguration
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonldLastConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastConfiguration
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceDTOJsonldLastConfiguration
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonldLastConfiguration
     */
    'device_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceDTOJsonldLastState
 */
export interface OutputDeviceDTOJsonldLastState {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    'external_status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOJsonldLastState
     */
    'device_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceDTOLastConfiguration
 */
export interface OutputDeviceDTOLastConfiguration {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOLastConfiguration
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOLastConfiguration
     */
    'timestamp'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceDTOLastConfiguration
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOLastConfiguration
     */
    'device_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceDTOLastState
 */
export interface OutputDeviceDTOLastState {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOLastState
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOLastState
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOLastState
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceDTOLastState
     */
    'external_status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceDTOLastState
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceDTOLastState
     */
    'device_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceEventDTO
 */
export interface OutputDeviceEventDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTO
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTO
     */
    'severity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTO
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceEventDTO
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTO
     */
    'device_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTO
     */
    'location_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTO
     */
    'organization_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceEventDTOJsonld
 */
export interface OutputDeviceEventDTOJsonld {
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceEventDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'severity'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'message'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'device_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'location_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceEventDTOJsonld
     */
    'organization_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceStateDTO
 */
export interface OutputDeviceStateDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStateDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTO
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTO
     */
    'external_status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceStateDTO
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStateDTO
     */
    'device_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputDeviceStateDTOJsonld
 */
export interface OutputDeviceStateDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceStateDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStateDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTOJsonld
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTOJsonld
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStateDTOJsonld
     */
    'external_status'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputDeviceStateDTOJsonld
     */
    'data'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStateDTOJsonld
     */
    'device_id'?: number;
}
/**
 * Class DeviceStatusMapping
 * @export
 * @interface OutputDeviceStatusMappingDTO
 */
export interface OutputDeviceStatusMappingDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStatusMappingDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStatusMappingDTO
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStatusMappingDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStatusMappingDTO
     */
    'external_status'?: string;
}
/**
 * Class DeviceStatusMapping
 * @export
 * @interface OutputDeviceStatusMappingDTOJsonld
 */
export interface OutputDeviceStatusMappingDTOJsonld {
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStatusMappingDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStatusMappingDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceStatusMappingDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStatusMappingDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceStatusMappingDTOJsonld
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStatusMappingDTOJsonld
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceStatusMappingDTOJsonld
     */
    'external_status'?: string;
}
/**
 * 
 * @export
 * @interface OutputDeviceTypeDTO
 */
export interface OutputDeviceTypeDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceTypeDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceTypeDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceTypeDTO
     */
    'external_id'?: string;
}
/**
 * 
 * @export
 * @interface OutputDeviceTypeDTOJsonld
 */
export interface OutputDeviceTypeDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputDeviceTypeDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceTypeDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceTypeDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputDeviceTypeDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceTypeDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OutputDeviceTypeDTOJsonld
     */
    'external_id'?: string;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonDTO
 */
export interface OutputFmonDaemonDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTO
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTO
     */
    'last_communication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTO
     */
    'connected_since'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonDTO
     */
    'location_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonDTOJsonld
 */
export interface OutputFmonDaemonDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    'last_communication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    'connected_since'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonDTOJsonld
     */
    'location_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonRegistrationRequestDTO
 */
export interface OutputFmonDaemonRegistrationRequestDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'fmond_auth_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'balena_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'user_approved_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'cleared'?: boolean;
    /**
     * 
     * @type {OutputFmonDaemonRegistrationRequestDTOLocation}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'location'?: OutputFmonDaemonRegistrationRequestDTOLocation | null;
    /**
     * 
     * @type {OutputFmonDaemonRegistrationRequestDTOFmonDaemon}
     * @memberof OutputFmonDaemonRegistrationRequestDTO
     */
    'fmon_daemon'?: OutputFmonDaemonRegistrationRequestDTOFmonDaemon | null;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonRegistrationRequestDTOFmonDaemon
 */
export interface OutputFmonDaemonRegistrationRequestDTOFmonDaemon {
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOFmonDaemon
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOFmonDaemon
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOFmonDaemon
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOFmonDaemon
     */
    'last_communication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOFmonDaemon
     */
    'connected_since'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOFmonDaemon
     */
    'location_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonRegistrationRequestDTOJsonld
 */
export interface OutputFmonDaemonRegistrationRequestDTOJsonld {
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'fmond_auth_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'balena_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'created_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'user_approved_at'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'cleared'?: boolean;
    /**
     * 
     * @type {OutputFmonDaemonRegistrationRequestDTOJsonldLocation}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'location'?: OutputFmonDaemonRegistrationRequestDTOJsonldLocation | null;
    /**
     * 
     * @type {OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonld
     */
    'fmon_daemon'?: OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon | null;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
 */
export interface OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    'version'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    'last_communication'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    'connected_since'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldFmonDaemon
     */
    'location_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonRegistrationRequestDTOJsonldLocation
 */
export interface OutputFmonDaemonRegistrationRequestDTOJsonldLocation {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    'organization_id'?: number;
    /**
     * 
     * @type {OutputLocationAggregatedDeviceStatusesDTOJsonld}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    'device_statuses'?: OutputLocationAggregatedDeviceStatusesDTOJsonld;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOJsonldLocation
     */
    'latitude': number;
}
/**
 * 
 * @export
 * @interface OutputFmonDaemonRegistrationRequestDTOLocation
 */
export interface OutputFmonDaemonRegistrationRequestDTOLocation {
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOLocation
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOLocation
     */
    'organization_id'?: number;
    /**
     * 
     * @type {OutputLocationAggregatedDeviceStatusesDTO}
     * @memberof OutputFmonDaemonRegistrationRequestDTOLocation
     */
    'device_statuses'?: OutputLocationAggregatedDeviceStatusesDTO;
    /**
     * 
     * @type {string}
     * @memberof OutputFmonDaemonRegistrationRequestDTOLocation
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOLocation
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof OutputFmonDaemonRegistrationRequestDTOLocation
     */
    'latitude': number;
}
/**
 * 
 * @export
 * @interface OutputFmondCertDTO
 */
export interface OutputFmondCertDTO {
    /**
     * 
     * @type {string}
     * @memberof OutputFmondCertDTO
     */
    'cert'?: string;
}
/**
 * 
 * @export
 * @interface OutputLocationAggregatedDeviceStatusesDTO
 */
export interface OutputLocationAggregatedDeviceStatusesDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTO
     */
    'ok'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTO
     */
    'warning'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTO
     */
    'error'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTO
     */
    'unknown'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTO
     */
    'unmapped'?: number;
}
/**
 * 
 * @export
 * @interface OutputLocationAggregatedDeviceStatusesDTOJsonld
 */
export interface OutputLocationAggregatedDeviceStatusesDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    'ok'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    'warning'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    'error'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    'unknown'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationAggregatedDeviceStatusesDTOJsonld
     */
    'unmapped'?: number;
}
/**
 * 
 * @export
 * @interface OutputLocationDTO
 */
export interface OutputLocationDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTO
     */
    'organization_id'?: number;
    /**
     * 
     * @type {OutputLocationAggregatedDeviceStatusesDTO}
     * @memberof OutputLocationDTO
     */
    'device_statuses'?: OutputLocationAggregatedDeviceStatusesDTO;
    /**
     * 
     * @type {string}
     * @memberof OutputLocationDTO
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTO
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTO
     */
    'latitude': number;
}
/**
 * 
 * @export
 * @interface OutputLocationDTOJsonld
 */
export interface OutputLocationDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputLocationDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputLocationDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputLocationDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTOJsonld
     */
    'organization_id'?: number;
    /**
     * 
     * @type {OutputLocationAggregatedDeviceStatusesDTOJsonld}
     * @memberof OutputLocationDTOJsonld
     */
    'device_statuses'?: OutputLocationAggregatedDeviceStatusesDTOJsonld;
    /**
     * 
     * @type {string}
     * @memberof OutputLocationDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTOJsonld
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof OutputLocationDTOJsonld
     */
    'latitude': number;
}
/**
 * Class Organization
 * @export
 * @interface OutputOrganizationDTO
 */
export interface OutputOrganizationDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputOrganizationDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputOrganizationDTO
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputOrganizationDTO
     */
    'name': string;
}
/**
 * Class Organization
 * @export
 * @interface OutputOrganizationDTOJsonld
 */
export interface OutputOrganizationDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputOrganizationDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputOrganizationDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputOrganizationDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputOrganizationDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputOrganizationDTOJsonld
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputOrganizationDTOJsonld
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface OutputRuleDTO
 */
export interface OutputRuleDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputRuleDTO
     */
    'id'?: number;
    /**
     * 
     * @type {OutputDeviceTypeDTO}
     * @memberof OutputRuleDTO
     */
    'device_type'?: OutputDeviceTypeDTO;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputRuleDTO
     */
    'rule_data'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTO
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTO
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTO
     */
    'message_type'?: string;
}
/**
 * 
 * @export
 * @interface OutputRuleDTOJsonld
 */
export interface OutputRuleDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputRuleDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {OutputDeviceTypeDTOJsonld}
     * @memberof OutputRuleDTOJsonld
     */
    'device_type'?: OutputDeviceTypeDTOJsonld;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputRuleDTOJsonld
     */
    'rule_data'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTOJsonld
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTOJsonld
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleDTOJsonld
     */
    'message_type'?: string;
}
/**
 * 
 * @export
 * @interface OutputRuleTriggerDTO
 */
export interface OutputRuleTriggerDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTO
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTO
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTO
     */
    'severity'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTO
     */
    'rule_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTO
     */
    'device_state_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTO
     */
    'device_event_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTO
     */
    'device_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTO
     */
    'location_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTO
     */
    'organization_id'?: number;
}
/**
 * 
 * @export
 * @interface OutputRuleTriggerDTOJsonld
 */
export interface OutputRuleTriggerDTOJsonld {
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'timestamp'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'message'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'severity'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'rule_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'device_state_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'device_event_id'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'device_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'location_id'?: number;
    /**
     * 
     * @type {number}
     * @memberof OutputRuleTriggerDTOJsonld
     */
    'organization_id'?: number;
}
/**
 * Class User
 * @export
 * @interface OutputUserDTO
 */
export interface OutputUserDTO {
    /**
     * 
     * @type {number}
     * @memberof OutputUserDTO
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTO
     */
    'username'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputUserDTO
     */
    'organization_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTO
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTO
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTO
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputUserDTO
     */
    'roles': Array<string>;
}
/**
 * Class User
 * @export
 * @interface OutputUserDTOJsonld
 */
export interface OutputUserDTOJsonld {
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof OutputUserDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {number}
     * @memberof OutputUserDTOJsonld
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTOJsonld
     */
    'username'?: string;
    /**
     * 
     * @type {number}
     * @memberof OutputUserDTOJsonld
     */
    'organization_id'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTOJsonld
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTOJsonld
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof OutputUserDTOJsonld
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OutputUserDTOJsonld
     */
    'roles': Array<string>;
}
/**
 * 
 * @export
 * @interface UpdateDeviceDTO
 */
export interface UpdateDeviceDTO {
    /**
     * 
     * @type {number}
     * @memberof UpdateDeviceDTO
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceDTO
     */
    'external_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceDTO
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateDeviceDTOJsonld
 */
export interface UpdateDeviceDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof UpdateDeviceDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeviceDTOJsonld
     */
    'device_type_id'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceDTOJsonld
     */
    'external_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceDTOJsonld
     */
    'name': string;
}
/**
 * Class DeviceStatusMapping
 * @export
 * @interface UpdateDeviceStatusMappingDTO
 */
export interface UpdateDeviceStatusMappingDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceStatusMappingDTO
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceStatusMappingDTO
     */
    'external_status'?: string;
}
/**
 * Class DeviceStatusMapping
 * @export
 * @interface UpdateDeviceStatusMappingDTOJsonld
 */
export interface UpdateDeviceStatusMappingDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof UpdateDeviceStatusMappingDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceStatusMappingDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceStatusMappingDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceStatusMappingDTOJsonld
     */
    'status': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceStatusMappingDTOJsonld
     */
    'external_status'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDeviceTypeDTO
 */
export interface UpdateDeviceTypeDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceTypeDTO
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceTypeDTO
     */
    'external_id'?: string;
}
/**
 * 
 * @export
 * @interface UpdateDeviceTypeDTOJsonld
 */
export interface UpdateDeviceTypeDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof UpdateDeviceTypeDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceTypeDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceTypeDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceTypeDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeviceTypeDTOJsonld
     */
    'external_id'?: string;
}
/**
 * 
 * @export
 * @interface UpdateFmonDaemonRegistrationRequestDTO
 */
export interface UpdateFmonDaemonRegistrationRequestDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFmonDaemonRegistrationRequestDTO
     */
    'approved': boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateFmonDaemonRegistrationRequestDTO
     */
    'location_id'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateLocationDTO
 */
export interface UpdateLocationDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDTO
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationDTO
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationDTO
     */
    'latitude': number;
}
/**
 * 
 * @export
 * @interface UpdateLocationDTOJsonld
 */
export interface UpdateLocationDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof UpdateLocationDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLocationDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationDTOJsonld
     */
    'longitude': number;
    /**
     * 
     * @type {number}
     * @memberof UpdateLocationDTOJsonld
     */
    'latitude': number;
}
/**
 * Class Organization
 * @export
 * @interface UpdateOrganizationDTO
 */
export interface UpdateOrganizationDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDTO
     */
    'name': string;
}
/**
 * Class Organization
 * @export
 * @interface UpdateOrganizationDTOJsonld
 */
export interface UpdateOrganizationDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof UpdateOrganizationDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrganizationDTOJsonld
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface UpdateRuleDTO
 */
export interface UpdateRuleDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTO
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRuleDTO
     */
    'rule_data'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTO
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTO
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTO
     */
    'message_type'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRuleDTOJsonld
 */
export interface UpdateRuleDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof UpdateRuleDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTOJsonld
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateRuleDTOJsonld
     */
    'rule_data'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTOJsonld
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTOJsonld
     */
    'severity': string;
    /**
     * 
     * @type {string}
     * @memberof UpdateRuleDTOJsonld
     */
    'message_type'?: string;
}
/**
 * Class User
 * @export
 * @interface UpdateUserDTO
 */
export interface UpdateUserDTO {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTO
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserDTO
     */
    'roles': Array<string>;
}
/**
 * Class User
 * @export
 * @interface UpdateUserDTOJsonld
 */
export interface UpdateUserDTOJsonld {
    /**
     * 
     * @type {OutputDeviceDTOJsonldContext}
     * @memberof UpdateUserDTOJsonld
     */
    '@context'?: OutputDeviceDTOJsonldContext;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTOJsonld
     */
    '@id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTOJsonld
     */
    '@type'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTOJsonld
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTOJsonld
     */
    'first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTOJsonld
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserDTOJsonld
     */
    'email': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateUserDTOJsonld
     */
    'roles': Array<string>;
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get new JWT token to login.
         * @summary Get new JWT token to login.
         * @param {Credentials} [credentials] Create new JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem: async (credentials?: Credentials, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(credentials, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * Get new JWT token to login.
         * @summary Get new JWT token to login.
         * @param {Credentials} [credentials] Create new JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCredentialsItem(credentials?: Credentials, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Authentication>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCredentialsItem(credentials, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * Get new JWT token to login.
         * @summary Get new JWT token to login.
         * @param {Credentials} [credentials] Create new JWT token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCredentialsItem(credentials?: Credentials, options?: any): AxiosPromise<Authentication> {
            return localVarFp.postCredentialsItem(credentials, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * Get new JWT token to login.
     * @summary Get new JWT token to login.
     * @param {Credentials} [credentials] Create new JWT token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public postCredentialsItem(credentials?: Credentials, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).postCredentialsItem(credentials, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceApi - axios parameter creator
 * @export
 */
export const DeviceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Device resource.
         * @summary Removes the Device resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeviceItem', 'id', id)
            const localVarPath = `/api/web/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {number} id Device identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceChildDevices: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceChildDevices', 'id', id)
            const localVarPath = `/api/web/devices/{id}/child_devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Device resources.
         * @summary Retrieves the collection of Device resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/devices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceItem', 'id', id)
            const localVarPath = `/api/web/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeDevices: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceTypeDevices', 'id', id)
            const localVarPath = `/api/web/device_types/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationDevicesCollection: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationDevicesCollection', 'id', id)
            const localVarPath = `/api/web/locations/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDevices: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationDevices', 'id', id)
            const localVarPath = `/api/web/organizations/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Device resource.
         * @summary Replaces the Device resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceDTO} updateDeviceDTO The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceItem: async (id: number, updateDeviceDTO: UpdateDeviceDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDeviceItem', 'id', id)
            // verify required parameter 'updateDeviceDTO' is not null or undefined
            assertParamExists('putDeviceItem', 'updateDeviceDTO', updateDeviceDTO)
            const localVarPath = `/api/web/devices/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceApi - functional programming interface
 * @export
 */
export const DeviceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Device resource.
         * @summary Removes the Device resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {number} id Device identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceChildDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceChildDevices(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Device resources.
         * @summary Retrieves the collection of Device resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeDevices(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationDevicesCollection(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationDevicesCollection(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationDevices(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Device resource.
         * @summary Replaces the Device resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceDTO} updateDeviceDTO The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDeviceItem(id: number, updateDeviceDTO: UpdateDeviceDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDeviceItem(id, updateDeviceDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceApi - factory interface
 * @export
 */
export const DeviceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceApiFp(configuration)
    return {
        /**
         * Removes the Device resource.
         * @summary Removes the Device resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeviceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {number} id Device identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceChildDevices(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getDeviceChildDevices(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Device resources.
         * @summary Retrieves the collection of Device resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getDeviceCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Device resource.
         * @summary Retrieves a Device resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceItem(id: number, options?: any): AxiosPromise<OutputDeviceDTO> {
            return localVarFp.getDeviceItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeDevices(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getDeviceTypeDevices(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationDevicesCollection(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getLocationDevicesCollection(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDevices(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getOrganizationDevices(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Device resource.
         * @summary Replaces the Device resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceDTO} updateDeviceDTO The updated Device resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceItem(id: number, updateDeviceDTO: UpdateDeviceDTO, options?: any): AxiosPromise<OutputDeviceDTO> {
            return localVarFp.putDeviceItem(id, updateDeviceDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceApi - object-oriented interface
 * @export
 * @class DeviceApi
 * @extends {BaseAPI}
 */
export class DeviceApi extends BaseAPI {
    /**
     * Removes the Device resource.
     * @summary Removes the Device resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public deleteDeviceItem(id: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).deleteDeviceItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Device resource.
     * @summary Retrieves a Device resource.
     * @param {number} id Device identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceChildDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDeviceChildDevices(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Device resources.
     * @summary Retrieves the collection of Device resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDeviceCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Device resource.
     * @summary Retrieves a Device resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceItem(id: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDeviceItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceType resource.
     * @summary Retrieves a DeviceType resource.
     * @param {number} id DeviceType identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getDeviceTypeDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getDeviceTypeDevices(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Location resource.
     * @summary Retrieves a Location resource.
     * @param {number} id Location identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getLocationDevicesCollection(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getLocationDevicesCollection(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Organization resource.
     * @summary Retrieves a Organization resource.
     * @param {number} id Organization identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public getOrganizationDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).getOrganizationDevices(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Device resource.
     * @summary Replaces the Device resource.
     * @param {number} id Resource identifier
     * @param {UpdateDeviceDTO} updateDeviceDTO The updated Device resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceApi
     */
    public putDeviceItem(id: number, updateDeviceDTO: UpdateDeviceDTO, options?: AxiosRequestConfig) {
        return DeviceApiFp(this.configuration).putDeviceItem(id, updateDeviceDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceConfigurationApi - axios parameter creator
 * @export
 */
export const DeviceConfigurationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of DeviceConfiguration resources.
         * @summary Retrieves the collection of DeviceConfiguration resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationCollection: async (page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/device_configurations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device.id'] = deviceId;
            }

            if (deviceId2) {
                localVarQueryParameter['device.id[]'] = deviceId2;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestamp[before]'] = timestampBefore;
            }

            if (timestampStrictlyBefore !== undefined) {
                localVarQueryParameter['timestamp[strictly_before]'] = timestampStrictlyBefore;
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestamp[after]'] = timestampAfter;
            }

            if (timestampStrictlyAfter !== undefined) {
                localVarQueryParameter['timestamp[strictly_after]'] = timestampStrictlyAfter;
            }

            if (orderTimestamp !== undefined) {
                localVarQueryParameter['order[timestamp]'] = orderTimestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceConfiguration resource.
         * @summary Retrieves a DeviceConfiguration resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceConfigurationItem', 'id', id)
            const localVarPath = `/api/web/device_configurations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceConfigurationApi - functional programming interface
 * @export
 */
export const DeviceConfigurationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceConfigurationApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of DeviceConfiguration resources.
         * @summary Retrieves the collection of DeviceConfiguration resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceConfigurationCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceConfigurationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceConfigurationCollection(page, itemsPerPage, deviceId, deviceId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceConfiguration resource.
         * @summary Retrieves a DeviceConfiguration resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceConfigurationItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceConfigurationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceConfigurationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceConfigurationApi - factory interface
 * @export
 */
export const DeviceConfigurationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceConfigurationApiFp(configuration)
    return {
        /**
         * Retrieves the collection of DeviceConfiguration resources.
         * @summary Retrieves the collection of DeviceConfiguration resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: any): AxiosPromise<Array<OutputDeviceConfigurationDTO>> {
            return localVarFp.getDeviceConfigurationCollection(page, itemsPerPage, deviceId, deviceId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceConfiguration resource.
         * @summary Retrieves a DeviceConfiguration resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceConfigurationItem(id: number, options?: any): AxiosPromise<OutputDeviceConfigurationDTO> {
            return localVarFp.getDeviceConfigurationItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceConfigurationApi - object-oriented interface
 * @export
 * @class DeviceConfigurationApi
 * @extends {BaseAPI}
 */
export class DeviceConfigurationApi extends BaseAPI {
    /**
     * Retrieves the collection of DeviceConfiguration resources.
     * @summary Retrieves the collection of DeviceConfiguration resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [deviceId] 
     * @param {Array<number>} [deviceId2] 
     * @param {string} [timestampBefore] 
     * @param {string} [timestampStrictlyBefore] 
     * @param {string} [timestampAfter] 
     * @param {string} [timestampStrictlyAfter] 
     * @param {'asc' | 'desc'} [orderTimestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public getDeviceConfigurationCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).getDeviceConfigurationCollection(page, itemsPerPage, deviceId, deviceId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceConfiguration resource.
     * @summary Retrieves a DeviceConfiguration resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceConfigurationApi
     */
    public getDeviceConfigurationItem(id: number, options?: AxiosRequestConfig) {
        return DeviceConfigurationApiFp(this.configuration).getDeviceConfigurationItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceEventApi - axios parameter creator
 * @export
 */
export const DeviceEventApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of DeviceEvent resources.
         * @summary Retrieves the collection of DeviceEvent resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {number} [deviceLocationId] 
         * @param {Array<number>} [deviceLocationId2] 
         * @param {number} [deviceOrganizationId] 
         * @param {Array<number>} [deviceOrganizationId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEventCollection: async (page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, deviceLocationId?: number, deviceLocationId2?: Array<number>, deviceOrganizationId?: number, deviceOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/device_events`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device.id'] = deviceId;
            }

            if (deviceId2) {
                localVarQueryParameter['device.id[]'] = deviceId2;
            }

            if (deviceLocationId !== undefined) {
                localVarQueryParameter['device.location.id'] = deviceLocationId;
            }

            if (deviceLocationId2) {
                localVarQueryParameter['device.location.id[]'] = deviceLocationId2;
            }

            if (deviceOrganizationId !== undefined) {
                localVarQueryParameter['device.organization.id'] = deviceOrganizationId;
            }

            if (deviceOrganizationId2) {
                localVarQueryParameter['device.organization.id[]'] = deviceOrganizationId2;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestamp[before]'] = timestampBefore;
            }

            if (timestampStrictlyBefore !== undefined) {
                localVarQueryParameter['timestamp[strictly_before]'] = timestampStrictlyBefore;
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestamp[after]'] = timestampAfter;
            }

            if (timestampStrictlyAfter !== undefined) {
                localVarQueryParameter['timestamp[strictly_after]'] = timestampStrictlyAfter;
            }

            if (orderTimestamp !== undefined) {
                localVarQueryParameter['order[timestamp]'] = orderTimestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceEvent resource.
         * @summary Retrieves a DeviceEvent resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEventItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceEventItem', 'id', id)
            const localVarPath = `/api/web/device_events/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceEventApi - functional programming interface
 * @export
 */
export const DeviceEventApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceEventApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of DeviceEvent resources.
         * @summary Retrieves the collection of DeviceEvent resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {number} [deviceLocationId] 
         * @param {Array<number>} [deviceLocationId2] 
         * @param {number} [deviceOrganizationId] 
         * @param {Array<number>} [deviceOrganizationId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceEventCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, deviceLocationId?: number, deviceLocationId2?: Array<number>, deviceOrganizationId?: number, deviceOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceEventDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceEventCollection(page, itemsPerPage, deviceId, deviceId2, deviceLocationId, deviceLocationId2, deviceOrganizationId, deviceOrganizationId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceEvent resource.
         * @summary Retrieves a DeviceEvent resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceEventItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceEventDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceEventItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceEventApi - factory interface
 * @export
 */
export const DeviceEventApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceEventApiFp(configuration)
    return {
        /**
         * Retrieves the collection of DeviceEvent resources.
         * @summary Retrieves the collection of DeviceEvent resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {number} [deviceLocationId] 
         * @param {Array<number>} [deviceLocationId2] 
         * @param {number} [deviceOrganizationId] 
         * @param {Array<number>} [deviceOrganizationId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEventCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, deviceLocationId?: number, deviceLocationId2?: Array<number>, deviceOrganizationId?: number, deviceOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: any): AxiosPromise<Array<OutputDeviceEventDTO>> {
            return localVarFp.getDeviceEventCollection(page, itemsPerPage, deviceId, deviceId2, deviceLocationId, deviceLocationId2, deviceOrganizationId, deviceOrganizationId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceEvent resource.
         * @summary Retrieves a DeviceEvent resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceEventItem(id: number, options?: any): AxiosPromise<OutputDeviceEventDTO> {
            return localVarFp.getDeviceEventItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceEventApi - object-oriented interface
 * @export
 * @class DeviceEventApi
 * @extends {BaseAPI}
 */
export class DeviceEventApi extends BaseAPI {
    /**
     * Retrieves the collection of DeviceEvent resources.
     * @summary Retrieves the collection of DeviceEvent resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [deviceId] 
     * @param {Array<number>} [deviceId2] 
     * @param {number} [deviceLocationId] 
     * @param {Array<number>} [deviceLocationId2] 
     * @param {number} [deviceOrganizationId] 
     * @param {Array<number>} [deviceOrganizationId2] 
     * @param {string} [timestampBefore] 
     * @param {string} [timestampStrictlyBefore] 
     * @param {string} [timestampAfter] 
     * @param {string} [timestampStrictlyAfter] 
     * @param {'asc' | 'desc'} [orderTimestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceEventApi
     */
    public getDeviceEventCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, deviceLocationId?: number, deviceLocationId2?: Array<number>, deviceOrganizationId?: number, deviceOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return DeviceEventApiFp(this.configuration).getDeviceEventCollection(page, itemsPerPage, deviceId, deviceId2, deviceLocationId, deviceLocationId2, deviceOrganizationId, deviceOrganizationId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceEvent resource.
     * @summary Retrieves a DeviceEvent resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceEventApi
     */
    public getDeviceEventItem(id: number, options?: AxiosRequestConfig) {
        return DeviceEventApiFp(this.configuration).getDeviceEventItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceStateApi - axios parameter creator
 * @export
 */
export const DeviceStateApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of DeviceState resources.
         * @summary Retrieves the collection of DeviceState resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStateCollection: async (page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/device_states`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device.id'] = deviceId;
            }

            if (deviceId2) {
                localVarQueryParameter['device.id[]'] = deviceId2;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestamp[before]'] = timestampBefore;
            }

            if (timestampStrictlyBefore !== undefined) {
                localVarQueryParameter['timestamp[strictly_before]'] = timestampStrictlyBefore;
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestamp[after]'] = timestampAfter;
            }

            if (timestampStrictlyAfter !== undefined) {
                localVarQueryParameter['timestamp[strictly_after]'] = timestampStrictlyAfter;
            }

            if (orderTimestamp !== undefined) {
                localVarQueryParameter['order[timestamp]'] = orderTimestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceState resource.
         * @summary Retrieves a DeviceState resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStateItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceStateItem', 'id', id)
            const localVarPath = `/api/web/device_states/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceStateApi - functional programming interface
 * @export
 */
export const DeviceStateApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceStateApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of DeviceState resources.
         * @summary Retrieves the collection of DeviceState resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceStateCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceStateDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceStateCollection(page, itemsPerPage, deviceId, deviceId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceState resource.
         * @summary Retrieves a DeviceState resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceStateItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceStateDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceStateItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceStateApi - factory interface
 * @export
 */
export const DeviceStateApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceStateApiFp(configuration)
    return {
        /**
         * Retrieves the collection of DeviceState resources.
         * @summary Retrieves the collection of DeviceState resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStateCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: any): AxiosPromise<Array<OutputDeviceStateDTO>> {
            return localVarFp.getDeviceStateCollection(page, itemsPerPage, deviceId, deviceId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceState resource.
         * @summary Retrieves a DeviceState resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStateItem(id: number, options?: any): AxiosPromise<OutputDeviceStateDTO> {
            return localVarFp.getDeviceStateItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceStateApi - object-oriented interface
 * @export
 * @class DeviceStateApi
 * @extends {BaseAPI}
 */
export class DeviceStateApi extends BaseAPI {
    /**
     * Retrieves the collection of DeviceState resources.
     * @summary Retrieves the collection of DeviceState resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [deviceId] 
     * @param {Array<number>} [deviceId2] 
     * @param {string} [timestampBefore] 
     * @param {string} [timestampStrictlyBefore] 
     * @param {string} [timestampAfter] 
     * @param {string} [timestampStrictlyAfter] 
     * @param {'asc' | 'desc'} [orderTimestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStateApi
     */
    public getDeviceStateCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return DeviceStateApiFp(this.configuration).getDeviceStateCollection(page, itemsPerPage, deviceId, deviceId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceState resource.
     * @summary Retrieves a DeviceState resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStateApi
     */
    public getDeviceStateItem(id: number, options?: AxiosRequestConfig) {
        return DeviceStateApiFp(this.configuration).getDeviceStateItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceStatusMappingApi - axios parameter creator
 * @export
 */
export const DeviceStatusMappingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the DeviceStatusMapping resource.
         * @summary Removes the DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceStatusMappingItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeviceStatusMappingItem', 'id', id)
            const localVarPath = `/api/web/device_status_mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of DeviceStatusMapping resources.
         * @summary Retrieves the collection of DeviceStatusMapping resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStatusMappingCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/device_status_mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceStatusMapping resource.
         * @summary Retrieves a DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStatusMappingItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceStatusMappingItem', 'id', id)
            const localVarPath = `/api/web/device_status_mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeStatusMappings: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceTypeStatusMappings', 'id', id)
            const localVarPath = `/api/web/device_types/{id}/device_status_mappings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a DeviceStatusMapping resource.
         * @summary Creates a DeviceStatusMapping resource.
         * @param {CreateDeviceStatusMappingDTO} createDeviceStatusMappingDTO The new DeviceStatusMapping resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceStatusMappingCollection: async (createDeviceStatusMappingDTO: CreateDeviceStatusMappingDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeviceStatusMappingDTO' is not null or undefined
            assertParamExists('postDeviceStatusMappingCollection', 'createDeviceStatusMappingDTO', createDeviceStatusMappingDTO)
            const localVarPath = `/api/web/device_status_mappings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeviceStatusMappingDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the DeviceStatusMapping resource.
         * @summary Replaces the DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceStatusMappingDTO} updateDeviceStatusMappingDTO The updated DeviceStatusMapping resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceStatusMappingItem: async (id: number, updateDeviceStatusMappingDTO: UpdateDeviceStatusMappingDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDeviceStatusMappingItem', 'id', id)
            // verify required parameter 'updateDeviceStatusMappingDTO' is not null or undefined
            assertParamExists('putDeviceStatusMappingItem', 'updateDeviceStatusMappingDTO', updateDeviceStatusMappingDTO)
            const localVarPath = `/api/web/device_status_mappings/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceStatusMappingDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceStatusMappingApi - functional programming interface
 * @export
 */
export const DeviceStatusMappingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceStatusMappingApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the DeviceStatusMapping resource.
         * @summary Removes the DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceStatusMappingItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceStatusMappingItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of DeviceStatusMapping resources.
         * @summary Retrieves the collection of DeviceStatusMapping resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceStatusMappingCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceStatusMappingDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceStatusMappingCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceStatusMapping resource.
         * @summary Retrieves a DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceStatusMappingItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceStatusMappingDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceStatusMappingItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeStatusMappings(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceStatusMappingDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeStatusMappings(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a DeviceStatusMapping resource.
         * @summary Creates a DeviceStatusMapping resource.
         * @param {CreateDeviceStatusMappingDTO} createDeviceStatusMappingDTO The new DeviceStatusMapping resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeviceStatusMappingCollection(createDeviceStatusMappingDTO: CreateDeviceStatusMappingDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceStatusMappingDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeviceStatusMappingCollection(createDeviceStatusMappingDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the DeviceStatusMapping resource.
         * @summary Replaces the DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceStatusMappingDTO} updateDeviceStatusMappingDTO The updated DeviceStatusMapping resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDeviceStatusMappingItem(id: number, updateDeviceStatusMappingDTO: UpdateDeviceStatusMappingDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceStatusMappingDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDeviceStatusMappingItem(id, updateDeviceStatusMappingDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceStatusMappingApi - factory interface
 * @export
 */
export const DeviceStatusMappingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceStatusMappingApiFp(configuration)
    return {
        /**
         * Removes the DeviceStatusMapping resource.
         * @summary Removes the DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceStatusMappingItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeviceStatusMappingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of DeviceStatusMapping resources.
         * @summary Retrieves the collection of DeviceStatusMapping resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStatusMappingCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceStatusMappingDTO>> {
            return localVarFp.getDeviceStatusMappingCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceStatusMapping resource.
         * @summary Retrieves a DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceStatusMappingItem(id: number, options?: any): AxiosPromise<OutputDeviceStatusMappingDTO> {
            return localVarFp.getDeviceStatusMappingItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeStatusMappings(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceStatusMappingDTO>> {
            return localVarFp.getDeviceTypeStatusMappings(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a DeviceStatusMapping resource.
         * @summary Creates a DeviceStatusMapping resource.
         * @param {CreateDeviceStatusMappingDTO} createDeviceStatusMappingDTO The new DeviceStatusMapping resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceStatusMappingCollection(createDeviceStatusMappingDTO: CreateDeviceStatusMappingDTO, options?: any): AxiosPromise<OutputDeviceStatusMappingDTO> {
            return localVarFp.postDeviceStatusMappingCollection(createDeviceStatusMappingDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the DeviceStatusMapping resource.
         * @summary Replaces the DeviceStatusMapping resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceStatusMappingDTO} updateDeviceStatusMappingDTO The updated DeviceStatusMapping resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceStatusMappingItem(id: number, updateDeviceStatusMappingDTO: UpdateDeviceStatusMappingDTO, options?: any): AxiosPromise<OutputDeviceStatusMappingDTO> {
            return localVarFp.putDeviceStatusMappingItem(id, updateDeviceStatusMappingDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceStatusMappingApi - object-oriented interface
 * @export
 * @class DeviceStatusMappingApi
 * @extends {BaseAPI}
 */
export class DeviceStatusMappingApi extends BaseAPI {
    /**
     * Removes the DeviceStatusMapping resource.
     * @summary Removes the DeviceStatusMapping resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStatusMappingApi
     */
    public deleteDeviceStatusMappingItem(id: number, options?: AxiosRequestConfig) {
        return DeviceStatusMappingApiFp(this.configuration).deleteDeviceStatusMappingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of DeviceStatusMapping resources.
     * @summary Retrieves the collection of DeviceStatusMapping resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStatusMappingApi
     */
    public getDeviceStatusMappingCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceStatusMappingApiFp(this.configuration).getDeviceStatusMappingCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceStatusMapping resource.
     * @summary Retrieves a DeviceStatusMapping resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStatusMappingApi
     */
    public getDeviceStatusMappingItem(id: number, options?: AxiosRequestConfig) {
        return DeviceStatusMappingApiFp(this.configuration).getDeviceStatusMappingItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceType resource.
     * @summary Retrieves a DeviceType resource.
     * @param {number} id DeviceType identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStatusMappingApi
     */
    public getDeviceTypeStatusMappings(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceStatusMappingApiFp(this.configuration).getDeviceTypeStatusMappings(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a DeviceStatusMapping resource.
     * @summary Creates a DeviceStatusMapping resource.
     * @param {CreateDeviceStatusMappingDTO} createDeviceStatusMappingDTO The new DeviceStatusMapping resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStatusMappingApi
     */
    public postDeviceStatusMappingCollection(createDeviceStatusMappingDTO: CreateDeviceStatusMappingDTO, options?: AxiosRequestConfig) {
        return DeviceStatusMappingApiFp(this.configuration).postDeviceStatusMappingCollection(createDeviceStatusMappingDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the DeviceStatusMapping resource.
     * @summary Replaces the DeviceStatusMapping resource.
     * @param {number} id Resource identifier
     * @param {UpdateDeviceStatusMappingDTO} updateDeviceStatusMappingDTO The updated DeviceStatusMapping resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceStatusMappingApi
     */
    public putDeviceStatusMappingItem(id: number, updateDeviceStatusMappingDTO: UpdateDeviceStatusMappingDTO, options?: AxiosRequestConfig) {
        return DeviceStatusMappingApiFp(this.configuration).putDeviceStatusMappingItem(id, updateDeviceStatusMappingDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeviceTypeApi - axios parameter creator
 * @export
 */
export const DeviceTypeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the DeviceType resource.
         * @summary Removes the DeviceType resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceTypeItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteDeviceTypeItem', 'id', id)
            const localVarPath = `/api/web/device_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of DeviceType resources.
         * @summary Retrieves the collection of DeviceType resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/device_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeDevices: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceTypeDevices', 'id', id)
            const localVarPath = `/api/web/device_types/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceTypeItem', 'id', id)
            const localVarPath = `/api/web/device_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeRules: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceTypeRules', 'id', id)
            const localVarPath = `/api/web/device_types/{id}/rules`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeStatusMappings: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceTypeStatusMappings', 'id', id)
            const localVarPath = `/api/web/device_types/{id}/device_status_mappings`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a DeviceType resource.
         * @summary Creates a DeviceType resource.
         * @param {CreateDeviceTypeDTO} createDeviceTypeDTO The new DeviceType resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceTypeCollection: async (createDeviceTypeDTO: CreateDeviceTypeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createDeviceTypeDTO' is not null or undefined
            assertParamExists('postDeviceTypeCollection', 'createDeviceTypeDTO', createDeviceTypeDTO)
            const localVarPath = `/api/web/device_types`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createDeviceTypeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the DeviceType resource.
         * @summary Replaces the DeviceType resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceTypeDTO} updateDeviceTypeDTO The updated DeviceType resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceTypeItem: async (id: number, updateDeviceTypeDTO: UpdateDeviceTypeDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putDeviceTypeItem', 'id', id)
            // verify required parameter 'updateDeviceTypeDTO' is not null or undefined
            assertParamExists('putDeviceTypeItem', 'updateDeviceTypeDTO', updateDeviceTypeDTO)
            const localVarPath = `/api/web/device_types/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeviceTypeDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeviceTypeApi - functional programming interface
 * @export
 */
export const DeviceTypeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeviceTypeApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the DeviceType resource.
         * @summary Removes the DeviceType resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDeviceTypeItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDeviceTypeItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of DeviceType resources.
         * @summary Retrieves the collection of DeviceType resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceTypeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeDevices(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceTypeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeRules(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputRuleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeRules(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeStatusMappings(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceStatusMappingDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeStatusMappings(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a DeviceType resource.
         * @summary Creates a DeviceType resource.
         * @param {CreateDeviceTypeDTO} createDeviceTypeDTO The new DeviceType resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postDeviceTypeCollection(createDeviceTypeDTO: CreateDeviceTypeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceTypeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postDeviceTypeCollection(createDeviceTypeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the DeviceType resource.
         * @summary Replaces the DeviceType resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceTypeDTO} updateDeviceTypeDTO The updated DeviceType resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putDeviceTypeItem(id: number, updateDeviceTypeDTO: UpdateDeviceTypeDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputDeviceTypeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putDeviceTypeItem(id, updateDeviceTypeDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeviceTypeApi - factory interface
 * @export
 */
export const DeviceTypeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeviceTypeApiFp(configuration)
    return {
        /**
         * Removes the DeviceType resource.
         * @summary Removes the DeviceType resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeviceTypeItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDeviceTypeItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of DeviceType resources.
         * @summary Retrieves the collection of DeviceType resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceTypeDTO>> {
            return localVarFp.getDeviceTypeCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeDevices(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getDeviceTypeDevices(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeItem(id: number, options?: any): AxiosPromise<OutputDeviceTypeDTO> {
            return localVarFp.getDeviceTypeItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeRules(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputRuleDTO>> {
            return localVarFp.getDeviceTypeRules(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeStatusMappings(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceStatusMappingDTO>> {
            return localVarFp.getDeviceTypeStatusMappings(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a DeviceType resource.
         * @summary Creates a DeviceType resource.
         * @param {CreateDeviceTypeDTO} createDeviceTypeDTO The new DeviceType resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postDeviceTypeCollection(createDeviceTypeDTO: CreateDeviceTypeDTO, options?: any): AxiosPromise<OutputDeviceTypeDTO> {
            return localVarFp.postDeviceTypeCollection(createDeviceTypeDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the DeviceType resource.
         * @summary Replaces the DeviceType resource.
         * @param {number} id Resource identifier
         * @param {UpdateDeviceTypeDTO} updateDeviceTypeDTO The updated DeviceType resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putDeviceTypeItem(id: number, updateDeviceTypeDTO: UpdateDeviceTypeDTO, options?: any): AxiosPromise<OutputDeviceTypeDTO> {
            return localVarFp.putDeviceTypeItem(id, updateDeviceTypeDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeviceTypeApi - object-oriented interface
 * @export
 * @class DeviceTypeApi
 * @extends {BaseAPI}
 */
export class DeviceTypeApi extends BaseAPI {
    /**
     * Removes the DeviceType resource.
     * @summary Removes the DeviceType resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public deleteDeviceTypeItem(id: number, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).deleteDeviceTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of DeviceType resources.
     * @summary Retrieves the collection of DeviceType resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public getDeviceTypeCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).getDeviceTypeCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceType resource.
     * @summary Retrieves a DeviceType resource.
     * @param {number} id DeviceType identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public getDeviceTypeDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).getDeviceTypeDevices(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceType resource.
     * @summary Retrieves a DeviceType resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public getDeviceTypeItem(id: number, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).getDeviceTypeItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceType resource.
     * @summary Retrieves a DeviceType resource.
     * @param {number} id DeviceType identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public getDeviceTypeRules(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).getDeviceTypeRules(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceType resource.
     * @summary Retrieves a DeviceType resource.
     * @param {number} id DeviceType identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public getDeviceTypeStatusMappings(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).getDeviceTypeStatusMappings(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a DeviceType resource.
     * @summary Creates a DeviceType resource.
     * @param {CreateDeviceTypeDTO} createDeviceTypeDTO The new DeviceType resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public postDeviceTypeCollection(createDeviceTypeDTO: CreateDeviceTypeDTO, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).postDeviceTypeCollection(createDeviceTypeDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the DeviceType resource.
     * @summary Replaces the DeviceType resource.
     * @param {number} id Resource identifier
     * @param {UpdateDeviceTypeDTO} updateDeviceTypeDTO The updated DeviceType resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeviceTypeApi
     */
    public putDeviceTypeItem(id: number, updateDeviceTypeDTO: UpdateDeviceTypeDTO, options?: AxiosRequestConfig) {
        return DeviceTypeApiFp(this.configuration).putDeviceTypeItem(id, updateDeviceTypeDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FMONDaemonCertificatesApi - axios parameter creator
 * @export
 */
export const FMONDaemonCertificatesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {object} body The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFmonDaemonRegistrationRequestItem: async (id: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clearFmonDaemonRegistrationRequestItem', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('clearFmonDaemonRegistrationRequestItem', 'body', body)
            const localVarPath = `/api/web/fmon-daemon-registration-requests/{id}/clear`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondRegistrationDTO} fmonDaemonInputFmondRegistrationDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegisterFmonDaemonCollection: async (fmonDaemonInputFmondRegistrationDTO: FmonDaemonInputFmondRegistrationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fmonDaemonInputFmondRegistrationDTO' is not null or undefined
            assertParamExists('postRegisterFmonDaemonCollection', 'fmonDaemonInputFmondRegistrationDTO', fmonDaemonInputFmondRegistrationDTO)
            const localVarPath = `/api/fmond/certs/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fmonDaemonInputFmondRegistrationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignLongTermRequestFmonDaemonCollection: async (fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fmonDaemonInputFmondCertRequestDTO' is not null or undefined
            assertParamExists('postSignLongTermRequestFmonDaemonCollection', 'fmonDaemonInputFmondCertRequestDTO', fmonDaemonInputFmondCertRequestDTO)
            const localVarPath = `/api/fmond/certs/long-term/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fmonDaemonInputFmondCertRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignShortTermRequestFmonDaemonCollection: async (fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fmonDaemonInputFmondCertRequestDTO' is not null or undefined
            assertParamExists('postSignShortTermRequestFmonDaemonCollection', 'fmonDaemonInputFmondCertRequestDTO', fmonDaemonInputFmondCertRequestDTO)
            const localVarPath = `/api/fmond/certs/short-term/sign`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fmonDaemonInputFmondCertRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFmonDaemonRegistrationRequestItem: async (id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putFmonDaemonRegistrationRequestItem', 'id', id)
            // verify required parameter 'updateFmonDaemonRegistrationRequestDTO' is not null or undefined
            assertParamExists('putFmonDaemonRegistrationRequestItem', 'updateFmonDaemonRegistrationRequestDTO', updateFmonDaemonRegistrationRequestDTO)
            const localVarPath = `/api/web/fmon-daemon-registration-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFmonDaemonRegistrationRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FMONDaemonCertificatesApi - functional programming interface
 * @export
 */
export const FMONDaemonCertificatesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FMONDaemonCertificatesApiAxiosParamCreator(configuration)
    return {
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {object} body The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearFmonDaemonRegistrationRequestItem(id: number, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonRegistrationRequestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearFmonDaemonRegistrationRequestItem(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondRegistrationDTO} fmonDaemonInputFmondRegistrationDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRegisterFmonDaemonCollection(fmonDaemonInputFmondRegistrationDTO: FmonDaemonInputFmondRegistrationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRegisterFmonDaemonCollection(fmonDaemonInputFmondRegistrationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignLongTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignLongTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postSignShortTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmondCertDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postSignShortTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFmonDaemonRegistrationRequestItem(id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonRegistrationRequestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFmonDaemonRegistrationRequestItem(id, updateFmonDaemonRegistrationRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FMONDaemonCertificatesApi - factory interface
 * @export
 */
export const FMONDaemonCertificatesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FMONDaemonCertificatesApiFp(configuration)
    return {
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {object} body The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFmonDaemonRegistrationRequestItem(id: number, body: object, options?: any): AxiosPromise<OutputFmonDaemonRegistrationRequestDTO> {
            return localVarFp.clearFmonDaemonRegistrationRequestItem(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondRegistrationDTO} fmonDaemonInputFmondRegistrationDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRegisterFmonDaemonCollection(fmonDaemonInputFmondRegistrationDTO: FmonDaemonInputFmondRegistrationDTO, options?: any): AxiosPromise<OutputFmonDaemonDTO> {
            return localVarFp.postRegisterFmonDaemonCollection(fmonDaemonInputFmondRegistrationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignLongTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options?: any): AxiosPromise<OutputFmonDaemonDTO> {
            return localVarFp.postSignLongTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a FmonDaemon resource.
         * @summary Creates a FmonDaemon resource.
         * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postSignShortTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options?: any): AxiosPromise<OutputFmondCertDTO> {
            return localVarFp.postSignShortTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFmonDaemonRegistrationRequestItem(id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options?: any): AxiosPromise<OutputFmonDaemonRegistrationRequestDTO> {
            return localVarFp.putFmonDaemonRegistrationRequestItem(id, updateFmonDaemonRegistrationRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FMONDaemonCertificatesApi - object-oriented interface
 * @export
 * @class FMONDaemonCertificatesApi
 * @extends {BaseAPI}
 */
export class FMONDaemonCertificatesApi extends BaseAPI {
    /**
     * Replaces the FmonDaemonRegistrationRequest resource.
     * @summary Replaces the FmonDaemonRegistrationRequest resource.
     * @param {number} id Resource identifier
     * @param {object} body The updated FmonDaemonRegistrationRequest resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FMONDaemonCertificatesApi
     */
    public clearFmonDaemonRegistrationRequestItem(id: number, body: object, options?: AxiosRequestConfig) {
        return FMONDaemonCertificatesApiFp(this.configuration).clearFmonDaemonRegistrationRequestItem(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FmonDaemon resource.
     * @summary Creates a FmonDaemon resource.
     * @param {FmonDaemonInputFmondRegistrationDTO} fmonDaemonInputFmondRegistrationDTO The new FmonDaemon resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FMONDaemonCertificatesApi
     */
    public postRegisterFmonDaemonCollection(fmonDaemonInputFmondRegistrationDTO: FmonDaemonInputFmondRegistrationDTO, options?: AxiosRequestConfig) {
        return FMONDaemonCertificatesApiFp(this.configuration).postRegisterFmonDaemonCollection(fmonDaemonInputFmondRegistrationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FmonDaemon resource.
     * @summary Creates a FmonDaemon resource.
     * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FMONDaemonCertificatesApi
     */
    public postSignLongTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options?: AxiosRequestConfig) {
        return FMONDaemonCertificatesApiFp(this.configuration).postSignLongTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a FmonDaemon resource.
     * @summary Creates a FmonDaemon resource.
     * @param {FmonDaemonInputFmondCertRequestDTO} fmonDaemonInputFmondCertRequestDTO The new FmonDaemon resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FMONDaemonCertificatesApi
     */
    public postSignShortTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO: FmonDaemonInputFmondCertRequestDTO, options?: AxiosRequestConfig) {
        return FMONDaemonCertificatesApiFp(this.configuration).postSignShortTermRequestFmonDaemonCollection(fmonDaemonInputFmondCertRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the FmonDaemonRegistrationRequest resource.
     * @summary Replaces the FmonDaemonRegistrationRequest resource.
     * @param {number} id Resource identifier
     * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FMONDaemonCertificatesApi
     */
    public putFmonDaemonRegistrationRequestItem(id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options?: AxiosRequestConfig) {
        return FMONDaemonCertificatesApiFp(this.configuration).putFmonDaemonRegistrationRequestItem(id, updateFmonDaemonRegistrationRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FmonDaemonApi - axios parameter creator
 * @export
 */
export const FmonDaemonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the FmonDaemon resource.
         * @summary Removes the FmonDaemon resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFmonDaemonItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteFmonDaemonItem', 'id', id)
            const localVarPath = `/api/web/fmon-daemons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of FmonDaemon resources.
         * @summary Retrieves the collection of FmonDaemon resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonCollection: async (page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/fmon-daemons`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderLastCommunication !== undefined) {
                localVarQueryParameter['order[last_communication]'] = orderLastCommunication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FmonDaemon resource.
         * @summary Retrieves a FmonDaemon resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFmonDaemonItem', 'id', id)
            const localVarPath = `/api/web/fmon-daemons/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationFmonDaemonsCollection: async (id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationFmonDaemonsCollection', 'id', id)
            const localVarPath = `/api/web/locations/{id}/fmon_daemons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderLastCommunication !== undefined) {
                localVarQueryParameter['order[last_communication]'] = orderLastCommunication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FmonDaemonApi - functional programming interface
 * @export
 */
export const FmonDaemonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FmonDaemonApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the FmonDaemon resource.
         * @summary Removes the FmonDaemon resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFmonDaemonItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFmonDaemonItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of FmonDaemon resources.
         * @summary Retrieves the collection of FmonDaemon resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFmonDaemonCollection(page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputFmonDaemonDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFmonDaemonCollection(page, itemsPerPage, orderLastCommunication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FmonDaemon resource.
         * @summary Retrieves a FmonDaemon resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFmonDaemonItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFmonDaemonItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationFmonDaemonsCollection(id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputFmonDaemonDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationFmonDaemonsCollection(id, page, itemsPerPage, orderLastCommunication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FmonDaemonApi - factory interface
 * @export
 */
export const FmonDaemonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FmonDaemonApiFp(configuration)
    return {
        /**
         * Removes the FmonDaemon resource.
         * @summary Removes the FmonDaemon resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFmonDaemonItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteFmonDaemonItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of FmonDaemon resources.
         * @summary Retrieves the collection of FmonDaemon resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonCollection(page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: any): AxiosPromise<Array<OutputFmonDaemonDTO>> {
            return localVarFp.getFmonDaemonCollection(page, itemsPerPage, orderLastCommunication, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FmonDaemon resource.
         * @summary Retrieves a FmonDaemon resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonItem(id: number, options?: any): AxiosPromise<OutputFmonDaemonDTO> {
            return localVarFp.getFmonDaemonItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationFmonDaemonsCollection(id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: any): AxiosPromise<Array<OutputFmonDaemonDTO>> {
            return localVarFp.getLocationFmonDaemonsCollection(id, page, itemsPerPage, orderLastCommunication, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FmonDaemonApi - object-oriented interface
 * @export
 * @class FmonDaemonApi
 * @extends {BaseAPI}
 */
export class FmonDaemonApi extends BaseAPI {
    /**
     * Removes the FmonDaemon resource.
     * @summary Removes the FmonDaemon resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonApi
     */
    public deleteFmonDaemonItem(id: number, options?: AxiosRequestConfig) {
        return FmonDaemonApiFp(this.configuration).deleteFmonDaemonItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of FmonDaemon resources.
     * @summary Retrieves the collection of FmonDaemon resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {'asc' | 'desc'} [orderLastCommunication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonApi
     */
    public getFmonDaemonCollection(page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return FmonDaemonApiFp(this.configuration).getFmonDaemonCollection(page, itemsPerPage, orderLastCommunication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FmonDaemon resource.
     * @summary Retrieves a FmonDaemon resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonApi
     */
    public getFmonDaemonItem(id: number, options?: AxiosRequestConfig) {
        return FmonDaemonApiFp(this.configuration).getFmonDaemonItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Location resource.
     * @summary Retrieves a Location resource.
     * @param {number} id Location identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {'asc' | 'desc'} [orderLastCommunication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonApi
     */
    public getLocationFmonDaemonsCollection(id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return FmonDaemonApiFp(this.configuration).getLocationFmonDaemonsCollection(id, page, itemsPerPage, orderLastCommunication, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FmonDaemonRegistrationRequestApi - axios parameter creator
 * @export
 */
export const FmonDaemonRegistrationRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {object} body The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFmonDaemonRegistrationRequestItem: async (id: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('clearFmonDaemonRegistrationRequestItem', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('clearFmonDaemonRegistrationRequestItem', 'body', body)
            const localVarPath = `/api/web/fmon-daemon-registration-requests/{id}/clear`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of FmonDaemonRegistrationRequest resources.
         * @summary Retrieves the collection of FmonDaemonRegistrationRequest resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [cleared] 
         * @param {string} [status] 
         * @param {Array<string>} [status2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonRegistrationRequestCollection: async (page?: number, itemsPerPage?: number, cleared?: boolean, status?: string, status2?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/fmon-daemon-registration-requests`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (cleared !== undefined) {
                localVarQueryParameter['cleared'] = cleared;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (status2) {
                localVarQueryParameter['status[]'] = status2;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a FmonDaemonRegistrationRequest resource.
         * @summary Retrieves a FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonRegistrationRequestItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getFmonDaemonRegistrationRequestItem', 'id', id)
            const localVarPath = `/api/web/fmon-daemon-registration-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFmonDaemonRegistrationRequestItem: async (id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putFmonDaemonRegistrationRequestItem', 'id', id)
            // verify required parameter 'updateFmonDaemonRegistrationRequestDTO' is not null or undefined
            assertParamExists('putFmonDaemonRegistrationRequestItem', 'updateFmonDaemonRegistrationRequestDTO', updateFmonDaemonRegistrationRequestDTO)
            const localVarPath = `/api/web/fmon-daemon-registration-requests/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFmonDaemonRegistrationRequestDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FmonDaemonRegistrationRequestApi - functional programming interface
 * @export
 */
export const FmonDaemonRegistrationRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FmonDaemonRegistrationRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {object} body The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clearFmonDaemonRegistrationRequestItem(id: number, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonRegistrationRequestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clearFmonDaemonRegistrationRequestItem(id, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of FmonDaemonRegistrationRequest resources.
         * @summary Retrieves the collection of FmonDaemonRegistrationRequest resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [cleared] 
         * @param {string} [status] 
         * @param {Array<string>} [status2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFmonDaemonRegistrationRequestCollection(page?: number, itemsPerPage?: number, cleared?: boolean, status?: string, status2?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputFmonDaemonRegistrationRequestDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFmonDaemonRegistrationRequestCollection(page, itemsPerPage, cleared, status, status2, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a FmonDaemonRegistrationRequest resource.
         * @summary Retrieves a FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFmonDaemonRegistrationRequestItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonRegistrationRequestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFmonDaemonRegistrationRequestItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putFmonDaemonRegistrationRequestItem(id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputFmonDaemonRegistrationRequestDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putFmonDaemonRegistrationRequestItem(id, updateFmonDaemonRegistrationRequestDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FmonDaemonRegistrationRequestApi - factory interface
 * @export
 */
export const FmonDaemonRegistrationRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FmonDaemonRegistrationRequestApiFp(configuration)
    return {
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {object} body The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clearFmonDaemonRegistrationRequestItem(id: number, body: object, options?: any): AxiosPromise<OutputFmonDaemonRegistrationRequestDTO> {
            return localVarFp.clearFmonDaemonRegistrationRequestItem(id, body, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of FmonDaemonRegistrationRequest resources.
         * @summary Retrieves the collection of FmonDaemonRegistrationRequest resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {boolean} [cleared] 
         * @param {string} [status] 
         * @param {Array<string>} [status2] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonRegistrationRequestCollection(page?: number, itemsPerPage?: number, cleared?: boolean, status?: string, status2?: Array<string>, options?: any): AxiosPromise<Array<OutputFmonDaemonRegistrationRequestDTO>> {
            return localVarFp.getFmonDaemonRegistrationRequestCollection(page, itemsPerPage, cleared, status, status2, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a FmonDaemonRegistrationRequest resource.
         * @summary Retrieves a FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFmonDaemonRegistrationRequestItem(id: number, options?: any): AxiosPromise<OutputFmonDaemonRegistrationRequestDTO> {
            return localVarFp.getFmonDaemonRegistrationRequestItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the FmonDaemonRegistrationRequest resource.
         * @summary Replaces the FmonDaemonRegistrationRequest resource.
         * @param {number} id Resource identifier
         * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putFmonDaemonRegistrationRequestItem(id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options?: any): AxiosPromise<OutputFmonDaemonRegistrationRequestDTO> {
            return localVarFp.putFmonDaemonRegistrationRequestItem(id, updateFmonDaemonRegistrationRequestDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FmonDaemonRegistrationRequestApi - object-oriented interface
 * @export
 * @class FmonDaemonRegistrationRequestApi
 * @extends {BaseAPI}
 */
export class FmonDaemonRegistrationRequestApi extends BaseAPI {
    /**
     * Replaces the FmonDaemonRegistrationRequest resource.
     * @summary Replaces the FmonDaemonRegistrationRequest resource.
     * @param {number} id Resource identifier
     * @param {object} body The updated FmonDaemonRegistrationRequest resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonRegistrationRequestApi
     */
    public clearFmonDaemonRegistrationRequestItem(id: number, body: object, options?: AxiosRequestConfig) {
        return FmonDaemonRegistrationRequestApiFp(this.configuration).clearFmonDaemonRegistrationRequestItem(id, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of FmonDaemonRegistrationRequest resources.
     * @summary Retrieves the collection of FmonDaemonRegistrationRequest resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {boolean} [cleared] 
     * @param {string} [status] 
     * @param {Array<string>} [status2] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonRegistrationRequestApi
     */
    public getFmonDaemonRegistrationRequestCollection(page?: number, itemsPerPage?: number, cleared?: boolean, status?: string, status2?: Array<string>, options?: AxiosRequestConfig) {
        return FmonDaemonRegistrationRequestApiFp(this.configuration).getFmonDaemonRegistrationRequestCollection(page, itemsPerPage, cleared, status, status2, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a FmonDaemonRegistrationRequest resource.
     * @summary Retrieves a FmonDaemonRegistrationRequest resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonRegistrationRequestApi
     */
    public getFmonDaemonRegistrationRequestItem(id: number, options?: AxiosRequestConfig) {
        return FmonDaemonRegistrationRequestApiFp(this.configuration).getFmonDaemonRegistrationRequestItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the FmonDaemonRegistrationRequest resource.
     * @summary Replaces the FmonDaemonRegistrationRequest resource.
     * @param {number} id Resource identifier
     * @param {UpdateFmonDaemonRegistrationRequestDTO} updateFmonDaemonRegistrationRequestDTO The updated FmonDaemonRegistrationRequest resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FmonDaemonRegistrationRequestApi
     */
    public putFmonDaemonRegistrationRequestItem(id: number, updateFmonDaemonRegistrationRequestDTO: UpdateFmonDaemonRegistrationRequestDTO, options?: AxiosRequestConfig) {
        return FmonDaemonRegistrationRequestApiFp(this.configuration).putFmonDaemonRegistrationRequestItem(id, updateFmonDaemonRegistrationRequestDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationApi - axios parameter creator
 * @export
 */
export const LocationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Location resource.
         * @summary Removes the Location resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteLocationItem', 'id', id)
            const localVarPath = `/api/web/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Location resources.
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationDevicesCollection: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationDevicesCollection', 'id', id)
            const localVarPath = `/api/web/locations/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationFmonDaemonsCollection: async (id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationFmonDaemonsCollection', 'id', id)
            const localVarPath = `/api/web/locations/{id}/fmon_daemons`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (orderLastCommunication !== undefined) {
                localVarQueryParameter['order[last_communication]'] = orderLastCommunication;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getLocationItem', 'id', id)
            const localVarPath = `/api/web/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLocations: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationLocations', 'id', id)
            const localVarPath = `/api/web/organizations/{id}/locations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Location resource.
         * @summary Creates a Location resource.
         * @param {CreateLocationDTO} createLocationDTO The new Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLocationCollection: async (createLocationDTO: CreateLocationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createLocationDTO' is not null or undefined
            assertParamExists('postLocationCollection', 'createLocationDTO', createLocationDTO)
            const localVarPath = `/api/web/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createLocationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Location resource.
         * @summary Replaces the Location resource.
         * @param {number} id Resource identifier
         * @param {UpdateLocationDTO} updateLocationDTO The updated Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLocationItem: async (id: number, updateLocationDTO: UpdateLocationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putLocationItem', 'id', id)
            // verify required parameter 'updateLocationDTO' is not null or undefined
            assertParamExists('putLocationItem', 'updateLocationDTO', updateLocationDTO)
            const localVarPath = `/api/web/locations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationApi - functional programming interface
 * @export
 */
export const LocationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Location resource.
         * @summary Removes the Location resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteLocationItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteLocationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Location resources.
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputLocationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationDevicesCollection(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationDevicesCollection(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationFmonDaemonsCollection(id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputFmonDaemonDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationFmonDaemonsCollection(id, page, itemsPerPage, orderLastCommunication, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputLocationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationLocations(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputLocationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationLocations(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Location resource.
         * @summary Creates a Location resource.
         * @param {CreateLocationDTO} createLocationDTO The new Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postLocationCollection(createLocationDTO: CreateLocationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputLocationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postLocationCollection(createLocationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Location resource.
         * @summary Replaces the Location resource.
         * @param {number} id Resource identifier
         * @param {UpdateLocationDTO} updateLocationDTO The updated Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putLocationItem(id: number, updateLocationDTO: UpdateLocationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputLocationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putLocationItem(id, updateLocationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationApi - factory interface
 * @export
 */
export const LocationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationApiFp(configuration)
    return {
        /**
         * Removes the Location resource.
         * @summary Removes the Location resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLocationItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteLocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Location resources.
         * @summary Retrieves the collection of Location resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputLocationDTO>> {
            return localVarFp.getLocationCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationDevicesCollection(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getLocationDevicesCollection(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Location identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {'asc' | 'desc'} [orderLastCommunication] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationFmonDaemonsCollection(id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: any): AxiosPromise<Array<OutputFmonDaemonDTO>> {
            return localVarFp.getLocationFmonDaemonsCollection(id, page, itemsPerPage, orderLastCommunication, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Location resource.
         * @summary Retrieves a Location resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationItem(id: number, options?: any): AxiosPromise<OutputLocationDTO> {
            return localVarFp.getLocationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLocations(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputLocationDTO>> {
            return localVarFp.getOrganizationLocations(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Location resource.
         * @summary Creates a Location resource.
         * @param {CreateLocationDTO} createLocationDTO The new Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postLocationCollection(createLocationDTO: CreateLocationDTO, options?: any): AxiosPromise<OutputLocationDTO> {
            return localVarFp.postLocationCollection(createLocationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Location resource.
         * @summary Replaces the Location resource.
         * @param {number} id Resource identifier
         * @param {UpdateLocationDTO} updateLocationDTO The updated Location resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putLocationItem(id: number, updateLocationDTO: UpdateLocationDTO, options?: any): AxiosPromise<OutputLocationDTO> {
            return localVarFp.putLocationItem(id, updateLocationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationApi - object-oriented interface
 * @export
 * @class LocationApi
 * @extends {BaseAPI}
 */
export class LocationApi extends BaseAPI {
    /**
     * Removes the Location resource.
     * @summary Removes the Location resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public deleteLocationItem(id: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).deleteLocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Location resources.
     * @summary Retrieves the collection of Location resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocationCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Location resource.
     * @summary Retrieves a Location resource.
     * @param {number} id Location identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationDevicesCollection(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocationDevicesCollection(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Location resource.
     * @summary Retrieves a Location resource.
     * @param {number} id Location identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {'asc' | 'desc'} [orderLastCommunication] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationFmonDaemonsCollection(id: number, page?: number, itemsPerPage?: number, orderLastCommunication?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocationFmonDaemonsCollection(id, page, itemsPerPage, orderLastCommunication, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Location resource.
     * @summary Retrieves a Location resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getLocationItem(id: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getLocationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Organization resource.
     * @summary Retrieves a Organization resource.
     * @param {number} id Organization identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public getOrganizationLocations(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).getOrganizationLocations(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Location resource.
     * @summary Creates a Location resource.
     * @param {CreateLocationDTO} createLocationDTO The new Location resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public postLocationCollection(createLocationDTO: CreateLocationDTO, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).postLocationCollection(createLocationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Location resource.
     * @summary Replaces the Location resource.
     * @param {number} id Resource identifier
     * @param {UpdateLocationDTO} updateLocationDTO The updated Location resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationApi
     */
    public putLocationItem(id: number, updateLocationDTO: UpdateLocationDTO, options?: AxiosRequestConfig) {
        return LocationApiFp(this.configuration).putLocationItem(id, updateLocationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganizationApi - axios parameter creator
 * @export
 */
export const OrganizationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Organization resource.
         * @summary Removes the Organization resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteOrganizationItem', 'id', id)
            const localVarPath = `/api/web/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Organization resources.
         * @summary Retrieves the collection of Organization resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDevices: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationDevices', 'id', id)
            const localVarPath = `/api/web/organizations/{id}/devices`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationItem', 'id', id)
            const localVarPath = `/api/web/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLocations: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationLocations', 'id', id)
            const localVarPath = `/api/web/organizations/{id}/locations`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationUsers', 'id', id)
            const localVarPath = `/api/web/organizations/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Organization resource.
         * @summary Creates a Organization resource.
         * @param {CreateOrganizationDTO} createOrganizationDTO The new Organization resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationCollection: async (createOrganizationDTO: CreateOrganizationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createOrganizationDTO' is not null or undefined
            assertParamExists('postOrganizationCollection', 'createOrganizationDTO', createOrganizationDTO)
            const localVarPath = `/api/web/organizations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createOrganizationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Organization resource.
         * @summary Replaces the Organization resource.
         * @param {number} id Resource identifier
         * @param {UpdateOrganizationDTO} updateOrganizationDTO The updated Organization resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrganizationItem: async (id: number, updateOrganizationDTO: UpdateOrganizationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putOrganizationItem', 'id', id)
            // verify required parameter 'updateOrganizationDTO' is not null or undefined
            assertParamExists('putOrganizationItem', 'updateOrganizationDTO', updateOrganizationDTO)
            const localVarPath = `/api/web/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOrganizationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganizationApi - functional programming interface
 * @export
 */
export const OrganizationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganizationApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Organization resource.
         * @summary Removes the Organization resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteOrganizationItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteOrganizationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Organization resources.
         * @summary Retrieves the collection of Organization resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputOrganizationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputDeviceDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationDevices(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputOrganizationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationLocations(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputLocationDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationLocations(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputUserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUsers(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Organization resource.
         * @summary Creates a Organization resource.
         * @param {CreateOrganizationDTO} createOrganizationDTO The new Organization resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postOrganizationCollection(createOrganizationDTO: CreateOrganizationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputOrganizationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postOrganizationCollection(createOrganizationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Organization resource.
         * @summary Replaces the Organization resource.
         * @param {number} id Resource identifier
         * @param {UpdateOrganizationDTO} updateOrganizationDTO The updated Organization resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putOrganizationItem(id: number, updateOrganizationDTO: UpdateOrganizationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputOrganizationDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putOrganizationItem(id, updateOrganizationDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganizationApi - factory interface
 * @export
 */
export const OrganizationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganizationApiFp(configuration)
    return {
        /**
         * Removes the Organization resource.
         * @summary Removes the Organization resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteOrganizationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Organization resources.
         * @summary Retrieves the collection of Organization resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputOrganizationDTO>> {
            return localVarFp.getOrganizationCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationDevices(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputDeviceDTO>> {
            return localVarFp.getOrganizationDevices(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationItem(id: number, options?: any): AxiosPromise<OutputOrganizationDTO> {
            return localVarFp.getOrganizationItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationLocations(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputLocationDTO>> {
            return localVarFp.getOrganizationLocations(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputUserDTO>> {
            return localVarFp.getOrganizationUsers(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Organization resource.
         * @summary Creates a Organization resource.
         * @param {CreateOrganizationDTO} createOrganizationDTO The new Organization resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postOrganizationCollection(createOrganizationDTO: CreateOrganizationDTO, options?: any): AxiosPromise<OutputOrganizationDTO> {
            return localVarFp.postOrganizationCollection(createOrganizationDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Organization resource.
         * @summary Replaces the Organization resource.
         * @param {number} id Resource identifier
         * @param {UpdateOrganizationDTO} updateOrganizationDTO The updated Organization resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putOrganizationItem(id: number, updateOrganizationDTO: UpdateOrganizationDTO, options?: any): AxiosPromise<OutputOrganizationDTO> {
            return localVarFp.putOrganizationItem(id, updateOrganizationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganizationApi - object-oriented interface
 * @export
 * @class OrganizationApi
 * @extends {BaseAPI}
 */
export class OrganizationApi extends BaseAPI {
    /**
     * Removes the Organization resource.
     * @summary Removes the Organization resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public deleteOrganizationItem(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).deleteOrganizationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Organization resources.
     * @summary Retrieves the collection of Organization resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizationCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Organization resource.
     * @summary Retrieves a Organization resource.
     * @param {number} id Organization identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationDevices(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizationDevices(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Organization resource.
     * @summary Retrieves a Organization resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationItem(id: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizationItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Organization resource.
     * @summary Retrieves a Organization resource.
     * @param {number} id Organization identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationLocations(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizationLocations(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Organization resource.
     * @summary Retrieves a Organization resource.
     * @param {number} id Organization identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public getOrganizationUsers(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).getOrganizationUsers(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Organization resource.
     * @summary Creates a Organization resource.
     * @param {CreateOrganizationDTO} createOrganizationDTO The new Organization resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public postOrganizationCollection(createOrganizationDTO: CreateOrganizationDTO, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).postOrganizationCollection(createOrganizationDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Organization resource.
     * @summary Replaces the Organization resource.
     * @param {number} id Resource identifier
     * @param {UpdateOrganizationDTO} updateOrganizationDTO The updated Organization resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganizationApi
     */
    public putOrganizationItem(id: number, updateOrganizationDTO: UpdateOrganizationDTO, options?: AxiosRequestConfig) {
        return OrganizationApiFp(this.configuration).putOrganizationItem(id, updateOrganizationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RuleApi - axios parameter creator
 * @export
 */
export const RuleApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the Rule resource.
         * @summary Removes the Rule resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRuleItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteRuleItem', 'id', id)
            const localVarPath = `/api/web/rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeRules: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDeviceTypeRules', 'id', id)
            const localVarPath = `/api/web/device_types/{id}/rules`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of Rule resources.
         * @summary Retrieves the collection of Rule resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Rule resource.
         * @summary Retrieves a Rule resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRuleItem', 'id', id)
            const localVarPath = `/api/web/rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a Rule resource.
         * @summary Creates a Rule resource.
         * @param {CreateRuleDTO} createRuleDTO The new Rule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRuleCollection: async (createRuleDTO: CreateRuleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createRuleDTO' is not null or undefined
            assertParamExists('postRuleCollection', 'createRuleDTO', createRuleDTO)
            const localVarPath = `/api/web/rules`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRuleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the Rule resource.
         * @summary Replaces the Rule resource.
         * @param {number} id Resource identifier
         * @param {UpdateRuleDTO} updateRuleDTO The updated Rule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRuleItem: async (id: number, updateRuleDTO: UpdateRuleDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putRuleItem', 'id', id)
            // verify required parameter 'updateRuleDTO' is not null or undefined
            assertParamExists('putRuleItem', 'updateRuleDTO', updateRuleDTO)
            const localVarPath = `/api/web/rules/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRuleDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuleApi - functional programming interface
 * @export
 */
export const RuleApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RuleApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the Rule resource.
         * @summary Removes the Rule resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteRuleItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteRuleItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDeviceTypeRules(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputRuleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDeviceTypeRules(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of Rule resources.
         * @summary Retrieves the collection of Rule resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRuleCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputRuleDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Rule resource.
         * @summary Retrieves a Rule resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRuleItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputRuleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a Rule resource.
         * @summary Creates a Rule resource.
         * @param {CreateRuleDTO} createRuleDTO The new Rule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postRuleCollection(createRuleDTO: CreateRuleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputRuleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postRuleCollection(createRuleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the Rule resource.
         * @summary Replaces the Rule resource.
         * @param {number} id Resource identifier
         * @param {UpdateRuleDTO} updateRuleDTO The updated Rule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putRuleItem(id: number, updateRuleDTO: UpdateRuleDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputRuleDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putRuleItem(id, updateRuleDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RuleApi - factory interface
 * @export
 */
export const RuleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RuleApiFp(configuration)
    return {
        /**
         * Removes the Rule resource.
         * @summary Removes the Rule resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRuleItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteRuleItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a DeviceType resource.
         * @summary Retrieves a DeviceType resource.
         * @param {number} id DeviceType identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeviceTypeRules(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputRuleDTO>> {
            return localVarFp.getDeviceTypeRules(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of Rule resources.
         * @summary Retrieves the collection of Rule resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputRuleDTO>> {
            return localVarFp.getRuleCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Rule resource.
         * @summary Retrieves a Rule resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleItem(id: number, options?: any): AxiosPromise<OutputRuleDTO> {
            return localVarFp.getRuleItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a Rule resource.
         * @summary Creates a Rule resource.
         * @param {CreateRuleDTO} createRuleDTO The new Rule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postRuleCollection(createRuleDTO: CreateRuleDTO, options?: any): AxiosPromise<OutputRuleDTO> {
            return localVarFp.postRuleCollection(createRuleDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the Rule resource.
         * @summary Replaces the Rule resource.
         * @param {number} id Resource identifier
         * @param {UpdateRuleDTO} updateRuleDTO The updated Rule resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putRuleItem(id: number, updateRuleDTO: UpdateRuleDTO, options?: any): AxiosPromise<OutputRuleDTO> {
            return localVarFp.putRuleItem(id, updateRuleDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RuleApi - object-oriented interface
 * @export
 * @class RuleApi
 * @extends {BaseAPI}
 */
export class RuleApi extends BaseAPI {
    /**
     * Removes the Rule resource.
     * @summary Removes the Rule resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public deleteRuleItem(id: number, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).deleteRuleItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a DeviceType resource.
     * @summary Retrieves a DeviceType resource.
     * @param {number} id DeviceType identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public getDeviceTypeRules(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).getDeviceTypeRules(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of Rule resources.
     * @summary Retrieves the collection of Rule resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public getRuleCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).getRuleCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Rule resource.
     * @summary Retrieves a Rule resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public getRuleItem(id: number, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).getRuleItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a Rule resource.
     * @summary Creates a Rule resource.
     * @param {CreateRuleDTO} createRuleDTO The new Rule resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public postRuleCollection(createRuleDTO: CreateRuleDTO, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).postRuleCollection(createRuleDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the Rule resource.
     * @summary Replaces the Rule resource.
     * @param {number} id Resource identifier
     * @param {UpdateRuleDTO} updateRuleDTO The updated Rule resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleApi
     */
    public putRuleItem(id: number, updateRuleDTO: UpdateRuleDTO, options?: AxiosRequestConfig) {
        return RuleApiFp(this.configuration).putRuleItem(id, updateRuleDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RuleTriggerApi - axios parameter creator
 * @export
 */
export const RuleTriggerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Retrieves the collection of RuleTrigger resources.
         * @summary Retrieves the collection of RuleTrigger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {number} [locationId] 
         * @param {Array<number>} [locationId2] 
         * @param {number} [locationOrganizationId] 
         * @param {Array<number>} [locationOrganizationId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleTriggerCollection: async (page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, locationId?: number, locationId2?: Array<number>, locationOrganizationId?: number, locationOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/rule_triggers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }

            if (deviceId !== undefined) {
                localVarQueryParameter['device.id'] = deviceId;
            }

            if (deviceId2) {
                localVarQueryParameter['device.id[]'] = deviceId2;
            }

            if (locationId !== undefined) {
                localVarQueryParameter['location.id'] = locationId;
            }

            if (locationId2) {
                localVarQueryParameter['location.id[]'] = locationId2;
            }

            if (locationOrganizationId !== undefined) {
                localVarQueryParameter['location.organization.id'] = locationOrganizationId;
            }

            if (locationOrganizationId2) {
                localVarQueryParameter['location.organization.id[]'] = locationOrganizationId2;
            }

            if (timestampBefore !== undefined) {
                localVarQueryParameter['timestamp[before]'] = timestampBefore;
            }

            if (timestampStrictlyBefore !== undefined) {
                localVarQueryParameter['timestamp[strictly_before]'] = timestampStrictlyBefore;
            }

            if (timestampAfter !== undefined) {
                localVarQueryParameter['timestamp[after]'] = timestampAfter;
            }

            if (timestampStrictlyAfter !== undefined) {
                localVarQueryParameter['timestamp[strictly_after]'] = timestampStrictlyAfter;
            }

            if (orderTimestamp !== undefined) {
                localVarQueryParameter['order[timestamp]'] = orderTimestamp;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a RuleTrigger resource.
         * @summary Retrieves a RuleTrigger resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleTriggerItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getRuleTriggerItem', 'id', id)
            const localVarPath = `/api/web/rule_triggers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RuleTriggerApi - functional programming interface
 * @export
 */
export const RuleTriggerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RuleTriggerApiAxiosParamCreator(configuration)
    return {
        /**
         * Retrieves the collection of RuleTrigger resources.
         * @summary Retrieves the collection of RuleTrigger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {number} [locationId] 
         * @param {Array<number>} [locationId2] 
         * @param {number} [locationOrganizationId] 
         * @param {Array<number>} [locationOrganizationId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRuleTriggerCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, locationId?: number, locationId2?: Array<number>, locationOrganizationId?: number, locationOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputRuleTriggerDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleTriggerCollection(page, itemsPerPage, deviceId, deviceId2, locationId, locationId2, locationOrganizationId, locationOrganizationId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a RuleTrigger resource.
         * @summary Retrieves a RuleTrigger resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRuleTriggerItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputRuleTriggerDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleTriggerItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RuleTriggerApi - factory interface
 * @export
 */
export const RuleTriggerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RuleTriggerApiFp(configuration)
    return {
        /**
         * Retrieves the collection of RuleTrigger resources.
         * @summary Retrieves the collection of RuleTrigger resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {number} [deviceId] 
         * @param {Array<number>} [deviceId2] 
         * @param {number} [locationId] 
         * @param {Array<number>} [locationId2] 
         * @param {number} [locationOrganizationId] 
         * @param {Array<number>} [locationOrganizationId2] 
         * @param {string} [timestampBefore] 
         * @param {string} [timestampStrictlyBefore] 
         * @param {string} [timestampAfter] 
         * @param {string} [timestampStrictlyAfter] 
         * @param {'asc' | 'desc'} [orderTimestamp] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleTriggerCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, locationId?: number, locationId2?: Array<number>, locationOrganizationId?: number, locationOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: any): AxiosPromise<Array<OutputRuleTriggerDTO>> {
            return localVarFp.getRuleTriggerCollection(page, itemsPerPage, deviceId, deviceId2, locationId, locationId2, locationOrganizationId, locationOrganizationId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a RuleTrigger resource.
         * @summary Retrieves a RuleTrigger resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRuleTriggerItem(id: number, options?: any): AxiosPromise<OutputRuleTriggerDTO> {
            return localVarFp.getRuleTriggerItem(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RuleTriggerApi - object-oriented interface
 * @export
 * @class RuleTriggerApi
 * @extends {BaseAPI}
 */
export class RuleTriggerApi extends BaseAPI {
    /**
     * Retrieves the collection of RuleTrigger resources.
     * @summary Retrieves the collection of RuleTrigger resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {number} [deviceId] 
     * @param {Array<number>} [deviceId2] 
     * @param {number} [locationId] 
     * @param {Array<number>} [locationId2] 
     * @param {number} [locationOrganizationId] 
     * @param {Array<number>} [locationOrganizationId2] 
     * @param {string} [timestampBefore] 
     * @param {string} [timestampStrictlyBefore] 
     * @param {string} [timestampAfter] 
     * @param {string} [timestampStrictlyAfter] 
     * @param {'asc' | 'desc'} [orderTimestamp] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleTriggerApi
     */
    public getRuleTriggerCollection(page?: number, itemsPerPage?: number, deviceId?: number, deviceId2?: Array<number>, locationId?: number, locationId2?: Array<number>, locationOrganizationId?: number, locationOrganizationId2?: Array<number>, timestampBefore?: string, timestampStrictlyBefore?: string, timestampAfter?: string, timestampStrictlyAfter?: string, orderTimestamp?: 'asc' | 'desc', options?: AxiosRequestConfig) {
        return RuleTriggerApiFp(this.configuration).getRuleTriggerCollection(page, itemsPerPage, deviceId, deviceId2, locationId, locationId2, locationOrganizationId, locationOrganizationId2, timestampBefore, timestampStrictlyBefore, timestampAfter, timestampStrictlyAfter, orderTimestamp, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a RuleTrigger resource.
     * @summary Retrieves a RuleTrigger resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RuleTriggerApi
     */
    public getRuleTriggerItem(id: number, options?: AxiosRequestConfig) {
        return RuleTriggerApiFp(this.configuration).getRuleTriggerItem(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserItem', 'id', id)
            const localVarPath = `/api/web/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers: async (id: number, page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getOrganizationUsers', 'id', id)
            const localVarPath = `/api/web/organizations/{id}/users`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection: async (page?: number, itemsPerPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/web/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (itemsPerPage !== undefined) {
                localVarQueryParameter['itemsPerPage'] = itemsPerPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getUserItem', 'id', id)
            const localVarPath = `/api/web/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {CreateUserDTO} createUserDTO The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCollection: async (createUserDTO: CreateUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createUserDTO' is not null or undefined
            assertParamExists('postUserCollection', 'createUserDTO', createUserDTO)
            const localVarPath = `/api/web/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {number} id Resource identifier
         * @param {UpdateUserDTO} updateUserDTO The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserItem: async (id: number, updateUserDTO: UpdateUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('putUserItem', 'id', id)
            // verify required parameter 'updateUserDTO' is not null or undefined
            assertParamExists('putUserItem', 'updateUserDTO', updateUserDTO)
            const localVarPath = `/api/web/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication JWT required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrganizationUsers(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputUserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrganizationUsers(id, page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OutputUserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserCollection(page, itemsPerPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUserItem(id: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUserItem(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {CreateUserDTO} createUserDTO The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postUserCollection(createUserDTO: CreateUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postUserCollection(createUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {number} id Resource identifier
         * @param {UpdateUserDTO} updateUserDTO The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async putUserItem(id: number, updateUserDTO: UpdateUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OutputUserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.putUserItem(id, updateUserDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * Removes the User resource.
         * @summary Removes the User resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserItem(id: number, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a Organization resource.
         * @summary Retrieves a Organization resource.
         * @param {number} id Organization identifier
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationUsers(id: number, page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputUserDTO>> {
            return localVarFp.getOrganizationUsers(id, page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the collection of User resources.
         * @summary Retrieves the collection of User resources.
         * @param {number} [page] The collection page number
         * @param {number} [itemsPerPage] The number of items per page
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserCollection(page?: number, itemsPerPage?: number, options?: any): AxiosPromise<Array<OutputUserDTO>> {
            return localVarFp.getUserCollection(page, itemsPerPage, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a User resource.
         * @summary Retrieves a User resource.
         * @param {number} id Resource identifier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserItem(id: number, options?: any): AxiosPromise<OutputUserDTO> {
            return localVarFp.getUserItem(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Creates a User resource.
         * @summary Creates a User resource.
         * @param {CreateUserDTO} createUserDTO The new User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postUserCollection(createUserDTO: CreateUserDTO, options?: any): AxiosPromise<OutputUserDTO> {
            return localVarFp.postUserCollection(createUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * Replaces the User resource.
         * @summary Replaces the User resource.
         * @param {number} id Resource identifier
         * @param {UpdateUserDTO} updateUserDTO The updated User resource
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        putUserItem(id: number, updateUserDTO: UpdateUserDTO, options?: any): AxiosPromise<OutputUserDTO> {
            return localVarFp.putUserItem(id, updateUserDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * Removes the User resource.
     * @summary Removes the User resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserItem(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a Organization resource.
     * @summary Retrieves a Organization resource.
     * @param {number} id Organization identifier
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getOrganizationUsers(id: number, page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getOrganizationUsers(id, page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the collection of User resources.
     * @summary Retrieves the collection of User resources.
     * @param {number} [page] The collection page number
     * @param {number} [itemsPerPage] The number of items per page
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserCollection(page?: number, itemsPerPage?: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserCollection(page, itemsPerPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a User resource.
     * @summary Retrieves a User resource.
     * @param {number} id Resource identifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUserItem(id: number, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).getUserItem(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Creates a User resource.
     * @summary Creates a User resource.
     * @param {CreateUserDTO} createUserDTO The new User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public postUserCollection(createUserDTO: CreateUserDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).postUserCollection(createUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Replaces the User resource.
     * @summary Replaces the User resource.
     * @param {number} id Resource identifier
     * @param {UpdateUserDTO} updateUserDTO The updated User resource
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public putUserItem(id: number, updateUserDTO: UpdateUserDTO, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).putUserItem(id, updateUserDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


