import {ActionContext, Module} from "vuex";
import {RootState} from "@/store";
import {RuleTriggerApi} from "@/api";

export interface RuleTriggersState {}
const defaultState: RuleTriggersState = {}

type Context = ActionContext<RuleTriggersState, RootState>;

interface GetCollectionParams {
    page: number|undefined,
    itemsPerPage: number|undefined,
    deviceId: number|undefined,
    locationId: number|undefined,
    organizationId: number|undefined
    timestampBefore: string|undefined,
    timestampAfter: string|undefined,
    orderTimestamp: "asc"|"desc"|undefined
}

export const ruleTriggers: Module<RuleTriggersState, RootState> = {
    namespaced: true,
    state: defaultState,
    getters: {},
    mutations: {},
    actions: {
        async get(context: Context, payload: { ruleTriggerId: number }) {
            const api: RuleTriggerApi = context.rootGetters['api/ruleTriggerApi'];

            return (await api.getRuleTriggerItem(payload.ruleTriggerId)).data;
        },
        async getAll(context: Context, payload: GetCollectionParams|undefined) {
            const api: RuleTriggerApi = context.rootGetters['api/ruleTriggerApi'];

            if (!payload) {
                payload = {} as GetCollectionParams;
            }

            return (await api.getRuleTriggerCollection(
                payload.page,
                payload.itemsPerPage,
                payload.deviceId,
                undefined,
                payload.locationId,
                undefined,
                payload.organizationId,
                undefined,
                payload.timestampBefore,
                undefined,
                payload.timestampAfter,
                undefined,
                payload.orderTimestamp ?? "desc"
            )).data;
        },
        async getByDevice(context: Context, payload: { deviceId: number }) {
            return await context.dispatch('getAll',  { deviceId: payload.deviceId })
        },
        async getByLocation(context: Context, payload: { locationId: number }) {
            return await context.dispatch('getAll',  { locationId: payload.locationId })
        },
        async getByOrganization(context: Context, payload: { organizationId: number }) {
            return await context.dispatch('getAll', { organizationId: payload.organizationId })
        }
    }
}
